<div class="row match-height">

    <div class="col-xl-4 col-md-4 col-12">
        <div class="card card-congratulation-medal">

            <div class="card-body">
                <h5>Hoşgeldin 🎉 {{userName}}!</h5>
                <div class="d-flex align-items-center justify-content-end">
                    <p class="card-text font-small-3 mr-25 mb-0">{{today | date:'yyyy-MM-dd HH:mm'}}</p>
                </div>
                <div class="row">
                    <div class="col-6">
                        <p class="card-text font-small-3">Toplam Talep Sayısı</p>

                        <h3 class="col-lg-12 col-md-12 col-12 mb-75 mt-2 ml-5 pt-50">
                            <a routerLink="/home/tickets">{{tickets.length + newTicketUsers.length}}</a>
                        </h3>
                    </div>
                    <div class="col-4">
                        <div class="d-flex justify-content-end">
                            <div *ngIf="user.imageURL!=null;" class="avatar" placement="top"
                                routerLink="/home/myprofile">
                                <img src="../../../../assets/images/img/{{user.imageURL}}" class="circle"
                                    alt="{{getInitials(userName) }}" width="80" height="80" />
                            </div>
                            <!-- <ng-template #emptyProfilePicture> -->
                            <div *ngIf="user.imageURL==null" class="col-sm-6 col-xl-3 mb-2 mb-xl-0 mr-5">
                                <div class="media">
                                    <div class="avatar bg-light-success">
                                        <div class="avatar-content" routerLink="/home/myprofile"
                                            style="font-size: xx-large; padding: 40px;">
                                            {{ getInitials(userName) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- </ng-template> -->

                        </div>
                    </div>
                </div>
                <button routerLink="/home/tickets" type="button" class="btn btn-primary" rippleEffect>Talepleri
                    Görüntüle</button>
            </div>
        </div>
    </div>
    <div class="col-xl-8 col-md-8 col-12">
        <div class="card card-statistics">
            <div class="card-header">
                <h4 class="card-title">Talep İstatistikleri</h4>
            </div>
            <div class="card-body statistics-body">
                <div class="row">
                    <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0" (click)="onIconClicked({statusId: 2})">
                        <div class="media">
                            <div class="avatar bg-light-primary mr-2 pull-up">
                                <div class="avatar-content">
                                    <i data-feather="trending-up" class="avatar-icon"></i>
                                </div>
                            </div>
                            <div class="media-body my-auto" style="cursor: pointer;">
                                <h4 class="font-weight-bolder mb-0 ">{{ ticketsWithContinuingStatus }}</h4>
                                <p class="card-text font-small-3 mb-0">Devam Eden Talepler</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0" (click)="onIconClicked({statusId: 4})">
                        <div class="media">
                            <div class="avatar bg-light-info mr-2 pull-up">
                                <div class="avatar-content">
                                    <i data-feather="user" class="avatar-icon"></i>
                                </div>
                            </div>
                            <div class="media-body my-auto " style="cursor: pointer;">
                                <h4 class="font-weight-bolder mb-0">{{ ticketWithComplatedStatus }}</h4>
                                <p class="card-text font-small-3 mb-0">Tamamlanan Talepler</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-xl-3 mb-2 mb-sm-0" (click)="onIconClicked({userId:0})">
                        <div class="media">
                            <div class="avatar bg-light-danger mr-2 pull-up">
                                <div class="avatar-content">
                                    <i data-feather="eye" class="avatar-icon"></i>
                                </div>
                            </div>
                            <div class="media-body my-auto" style="cursor: pointer;">
                                <h4 class="font-weight-bolder mb-0">{{ followedTickets }}</h4>
                                <p class="card-text font-small-3 mb-0">Takip Edilen Talepler</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-xl-3" (click)="onIconClicked({statusId: 8})">
                        <div class="media">
                            <div class="avatar bg-light-success mr-2 pull-up">
                                <div class="avatar-content">
                                    <i data-feather="target" class="avatar-icon"></i>
                                </div>
                            </div>
                            <div class="media-body my-auto" style="cursor: pointer;">
                                <h4 class="font-weight-bolder mb-0">{{ ticketWithWaitingForAswerStatus }}</h4>
                                <p class="card-text font-small-3 mb-0">Müşteriden Cevap Beklenen Talepler</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-1"></div>
</div>


<div class="row" *ngIf="userRole=='Customer'||userRole=='CustomerUser'">
    <div class="col-lg-6 col-md-6 col-12">
        <ul ngbNav #nav="ngbNav" class="nav nav-fill nav-pills">
            <li ngbNavItem>
                <a ngbNavLink>Güncel Konuşmalar</a>
                <ng-template ngbNavContent>
                    <div class="card ">
                        <div class="card-body">
                            <div *ngFor="let item of ticketMessages">
                                <div class="row">
                                    <div class="col-3 mt-2">
                                        <div *ngIf="item.imageURL!=null; else emptyImageTemplate" class="avatar"
                                            placement="top">
                                            <img src="../../../../assets/images/img/{{item.imageURL}}" class="rounded"
                                                alt="{{getInitials(item.userName) }}" ng-reflect-placement="top"
                                                placement="top" container="body" ng-reflect-container="body"
                                                ng-reflect-ngb-tooltip="[object Object]" ngbTooltip="{{item.userName}}"
                                                width="35" height="35" />
                                        </div>
                                        <ng-template #emptyImageTemplate>
                                            <div *ngIf="user.imageURL==null"
                                                class="avatar ml-0 ng-star-inserted bg-light-info"
                                                style="width: 40px; height: 40px;" placement="top">
                                                <div class="avatar-content text-center" ng-reflect-placement="top"
                                                    placement="top" container="body" ng-reflect-container="body"
                                                    ng-reflect-ngb-tooltip="[object Object]"
                                                    ngbTooltip="{{item.userName}}"
                                                    style="font-size: medium; margin: 5px 0px 0px 5px;">
                                                    {{ getInitials(item.userName) }}
                                                </div>
                                            </div>
                                        </ng-template>

                                    </div>
                                    <div class="col-9">
                                        <div class="d-flex justify-content-end align-items-center mb-25">
                                            <p style="cursor: pointer;"
                                                class="badge badge-pill badge-success font-small-5 font-weight-bold line-height-2"
                                                routerLink="/home/details/{{item.ticketId}}">
                                                #{{item.trackingNumber}}
                                            </p>
                                        </div>
                                        <div
                                            *ngIf="item.text == 'undefined' || item.text == '[object Object]' else emptyMessageText">
                                        </div>
                                        <ng-template #emptyMessageText>
                                            <p class="card-text text-left"
                                                [innerHTML]="item.text.length>40? item.text.slice(0,40)+'...':item.text">
                                            </p>
                                        </ng-template>

                                        <span
                                            class="d-flex justify-content-end align-items-center font-small-1 font-weight-bold">
                                            <span *ngIf="item.documentId!=0 " class="mr-2" ng-reflect-placement="top"
                                                placement="top" container="body" ng-reflect-container="body"
                                                ng-reflect-ngb-tooltip="[object Object]"
                                                ngbTooltip={{item.documentUrl}}>
                                                <img src="assets/images/icons/doc.png" alt="data.json" class=""
                                                    height="17"></span>

                                            <span *ngIf="item.ticketStatusId==1 " class="mr-2"
                                                ng-reflect-placement="top" placement="top" container="body"
                                                ng-reflect-container="body" ng-reflect-ngb-tooltip="[object Object]"
                                                ngbTooltip="Yeni Oluşturuldu"><i data-feather="target"
                                                    class="avatar bg-light-info avatar-icon"></i></span>
                                            <span *ngIf="item.ticketStatusId==2" class="mr-2" ng-reflect-placement="top"
                                                placement="top" container="body" ng-reflect-container="body"
                                                ng-reflect-ngb-tooltip="[object Object]"
                                                ngbTooltip="Danışmandan Cevap Bekleniyor"><i data-feather="target"
                                                    class="avatar bg-light-primary avatar-icon"></i></span>
                                            <span *ngIf="item.ticketStatusId==4" class="mr-2" ng-reflect-placement="top"
                                                placement="top" container="body" ng-reflect-container="body"
                                                ng-reflect-ngb-tooltip="[object Object]" ngbTooltip="Tamamlandı"> <i
                                                    data-feather="target"
                                                    class="avatar bg-light-success avatar-icon"></i></span>
                                            <span *ngIf="item.ticketStatusId==5" class="mr-2" ng-reflect-placement="top"
                                                placement="top" container="body" ng-reflect-container="body"
                                                ng-reflect-ngb-tooltip="[object Object]" ngbTooltip="İptal Edildi"><i
                                                    data-feather="target"
                                                    class="avatar bg-light-danger avatar-icon"></i></span>
                                            <span *ngIf="item.ticketStatusId==8" class="mr-2" ng-reflect-placement="top"
                                                placement="top" container="body" ng-reflect-container="body"
                                                ng-reflect-ngb-tooltip="[object Object]"
                                                ngbTooltip="Müşteriden Cevap Bekleniyor"><i data-feather="target"
                                                    class="avatar bg-light-warning avatar-icon"></i></span>
                                            {{item.sendedDate | date:'dd-MM-yyyy HH:mm'}}
                                        </span>
                                    </div>
                                </div>
                                <hr>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <div class="text-center">
                                        <button class="btn btn-group btn-outline-primary"
                                            (click)="previousPageForPartnersTicketMessages()">Önceki</button>
                                    </div>
                                </div>
                                <div class="col-6"></div>
                                <div class="col-3">
                                    <div class="text-center ">
                                        <button class="btn btn-group btn-outline-primary"
                                            (click)="nextPageForPartnersTicketMessages()">Sonraki</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink (click)="listofWatinigAnswerTickets(pageNumberForUrgentTickets)">Cevap Bekleyen
                    Talepler</a>
                <ng-template ngbNavContent>
                    <div class="card">
                        <div class="card-body">
                            <div *ngFor="let item of newUrgentTicketUsers">
                                <div class="row">
                                    <div class="col-2 mt-2 ml-3">
                                        <div class="d-flex justify-content-end align-items-center mb-25">
                                            <p style="cursor: pointer;"
                                                class="badge badge-pill badge-warning font-small-5 font-weight-bold line-height-2"
                                                routerLink="/home/details/{{item[0].ticketId}}">
                                                #{{item[0].trackingNumber}}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-3 mt-1">
                                        <div class="d-flex mb-0">
                                            <div *ngFor="let user of item; let i = index"
                                                class="avatar ml-0 pull-up ng-star-inserted"
                                                [ngClass]="{'bg-light-warning': user.typeName=='Sorumlu', 'bg-light-primary': user.typeName=='Takipçi'}"
                                                placement="top">
                                                <div *ngIf="i<2">

                                                    <span *ngIf="user.imageURL==null; else imageUserTemplate"
                                                        class="avatar-content avatar-status ng-star-inserted text-center"
                                                        ng-reflect-placement="top" placement="top" container="body"
                                                        ng-reflect-container="body"
                                                        ng-reflect-ngb-tooltip="[object Object]"
                                                        ngbTooltip="{{user.userFirstName}} {{user.userLastName}}">
                                                        <div>
                                                            {{user.userFirstName.charAt(0)}}{{user.userLastName.charAt(0)}}
                                                        </div>
                                                    </span>
                                                    <ng-template #imageUserTemplate>
                                                        <span
                                                            class="avatar-content avatar-status ng-star-inserted text-center"
                                                            ng-reflect-placement="top" placement="top" container="body"
                                                            ng-reflect-container="body"
                                                            ng-reflect-ngb-tooltip="[object Object]"
                                                            ngbTooltip="{{user.userFirstName}} {{user.userLastName}}">
                                                            <div
                                                                *ngIf="user.typeName!='Sorumlu'; else followersTemplate">
                                                                <div class="avatar" placement="top" style="border: 2px solid rgb(76, 76, 200);
                                                      border-radius: 50%;
                                                      padding: 1px;">
                                                                    <img src="../../../../assets/images/img/{{user.imageURL}}"
                                                                        style="border-radius:50px ;" width="25"
                                                                        height="25" />
                                                                </div>
                                                            </div>
                                                            <ng-template #followersTemplate>
                                                                <div class="avatar" placement="top" style="border: 2px solid orange;
                                                      border-radius: 50%;
                                                      padding: 1px;">
                                                                    <img src="../../../../assets/images/img/{{user.imageURL}}"
                                                                        style="border-radius:50px ;" width="25"
                                                                        height="25" />
                                                                </div>
                                                            </ng-template>
                                                        </span>
                                                    </ng-template>
                                                </div>
                                                <ng-container *ngIf="i===2">
                                                    <div class="avatar pull-up ng-star-inserted bg-light-secondary"
                                                        placement="top">
                                                        <span
                                                            class="avatar-content avatar-status ng-star-inserted text-center"
                                                            ng-reflect-placement="top" placement="top" container="body"
                                                            ng-reflect-container="body"
                                                            ng-reflect-ngb-tooltip="[object Object]"
                                                            ngbTooltip="+{{item.length - 2}} kişi">
                                                            <div>
                                                                +{{item.length - 2}}
                                                            </div>
                                                        </span>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-5">
                                        <p class="card-text text-left"
                                            [innerHTML]="item[0].subject.length>28? item[0].subject.slice(0,28)+'...':item[0].subject">
                                        </p>
                                        <span
                                            class="d-flex justify-content-end align-items-center font-small-1 ml-3 font-weight-bold">
                                            {{item[0].createdDate| date:'dd-MM-yyyy HH:mm'}}

                                        </span>
                                    </div>
                                </div>
                                <hr>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <div class="text-center">
                                        <button class="btn btn-group btn-outline-warning"
                                            (click)="previousPagePartnersUrgentTickets(pageNumberForUrgentTickets)">Önceki</button>
                                    </div>
                                </div>
                                <div class="col-6"></div>
                                <div class="col-3">
                                    <div class="text-center ">
                                        <button class="btn btn-group btn-outline-warning"
                                            (click)="nextPagePartnersUrgentTickets(pageNumberForUrgentTickets)">Sonraki</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-1"></div>
    </div>
    <div class="col-lg-6 col-md-6 col-12">
        <div class="card">
            <div class="card-header">
                <h4>
                    Onay Bekleyen Eforların Listesi
                </h4>
            </div>
            <div class="card-body">
                <div *ngFor="let item of efforts">
                    <div class="col-12">
                        <div class="d-flex justify-content-end align-items-center ">
                            <p style="cursor: pointer;"
                                class="badge badge-pill badge-light-info font-small-5 font-weight-bold line-height-2"
                                routerLink="/home/details/{{item.ticketId}}">
                                #{{item.trackingNumber}}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-2">
                            <div class="avatar ml-0 ng-star-inserted bg-light-info" style="width: 35px; height: 35px;"
                                placement="top">
                                <div class="avatar-content text-center" ng-reflect-placement="top" placement="top"
                                    container="body" ng-reflect-container="body"
                                    ng-reflect-ngb-tooltip="[object Object]" ngbTooltip="{{item.userName}}">
                                    <span style="font-size: larger; margin: 5px 0px 0px 5px;">{{
                                        getInitials(item.userName) }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row">
                                <div class="col-6">
                                    <div
                                        *ngIf="item.description == 'undefined' || item.description == '[object Object]' else emptyMessageText">
                                    </div>
                                    <ng-template #emptyMessageText>
                                        <p class="card-text text-left"
                                            [innerHTML]="item.description.length > 20 ? item.description.slice(0, 20) + '...' : item.description ">
                                        </p>
                                    </ng-template>
                                </div>
                                <div class="col-6">
                                    <span> {{item.time}} Saat
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <span class="mr-2" ng-reflect-placement="top" data-bs-toggle="modal"
                                data-bs-target="#acceptedeffort" (click)="selectedEffortId=item.id" placement="top"
                                container="body" ng-reflect-container="body" ng-reflect-ngb-tooltip="[object Object]"
                                ngbTooltip="Kabul Et">
                                <i data-feather="check" class="avatar bg-success avatar-icon"></i>
                            </span>
                            <span class="mr-2" ng-reflect-placement="top" data-bs-toggle="modal"
                                data-bs-target="#deniedeffort" (click)="selectedEffortId=item.id" placement="top"
                                container="body" ng-reflect-container="body" ng-reflect-ngb-tooltip="[object Object]"
                                ngbTooltip="Reddet">
                                <i data-feather="x" class="avatar bg-danger avatar-icon"></i>
                            </span>
                            <div class="d-flex justify-content-end align-items-center font-small-1 font-weight-bold">
                                {{item.startDate | date:'dd-MM-yyyy'}} {{item.startDate | date :'HH.mm'}}
                            </div>

                        </div>
                    </div>
                    <hr>
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class="text-center">
                            <button class="btn btn-group btn-outline-primary"
                                (click)="previousPageForEfforts(pageNumberForEfforts)">Önceki</button>
                        </div>
                    </div>
                    <div class="col-6"></div>
                    <div class="col-3">
                        <div class="text-center ">
                            <button class="btn btn-group btn-outline-primary"
                                (click)="nextPageForEfforts(pageNumberForEfforts)">Sonraki</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="deniedeffort" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Eforu Reddet</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                        class="mr-50"></i>Çıkış</button>
            </div>
            <div class="modal-body">
                Bekleyen Bu Eforu Reddetmek İstediğinize Emin Misiniz?
            </div>
            <div class="modal-footer">
                <div>
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                        (click)="getDeniedEffort(selectedEffortId)"><i data-feather="x"
                            class="mr-50"></i>Reddet</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="acceptedeffort" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Eforu Onayla</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                        class="mr-50"></i>Çıkış</button>
            </div>
            <div class="modal-body">
                Bekleyen Bu Eforu Onaylamak İstediğinize Emin Misiniz?
            </div>
            <div class="modal-footer">
                <div>
                    <button type="button" class="btn btn-success" data-bs-dismiss="modal"
                        (click)="getAcceptedEffort(selectedEffortId)"><i data-feather="check"
                            class="mr-50"></i>Onayla</button>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="row" *ngIf="userRole=='Admin' || userRole=='Consultant' || userRole=='ConsultantUser'">
    <div class="col-lg-4 col-md-6 col-12">
        <ul ngbNav #nav="ngbNav" class="nav nav-fill nav-pills">
            <li ngbNavItem>
                <a ngbNavLink>Güncel Konuşmalar</a>
                <ng-template ngbNavContent>
                    <div class="card ">
                        <div class="card-body">
                            <div *ngFor="let item of ticketMessages.slice(0, showItems)">
                                <div class="row">
                                    <div class="col-3 mt-2">
                                        <div *ngIf="item.imageURL!=null; else emptyImageTemplate" class="avatar"
                                            placement="top">
                                            <img src="../../../../assets/images/img/{{item.imageURL}}" class="rounded"
                                                alt="{{getInitials(item.userName) }}" ng-reflect-placement="top"
                                                placement="top" container="body" ng-reflect-container="body"
                                                ng-reflect-ngb-tooltip="[object Object]" ngbTooltip="{{item.userName}}"
                                                width="35" height="35" />
                                        </div>
                                        <ng-template #emptyImageTemplate>
                                            <div *ngIf="user.imageURL==null"
                                                class="avatar ml-0 ng-star-inserted bg-light-info"
                                                style="width: 40px; height: 40px;" placement="top">
                                                <div class="avatar-content text-center" ng-reflect-placement="top"
                                                    placement="top" container="body" ng-reflect-container="body"
                                                    ng-reflect-ngb-tooltip="[object Object]"
                                                    ngbTooltip="{{item.userName}}"
                                                    style="font-size: medium; margin: 5px 0px 0px 5px;">
                                                    {{ getInitials(item.userName) }}
                                                </div>
                                            </div>
                                        </ng-template>

                                    </div>
                                    <div class="col-9">
                                        <div class="d-flex justify-content-end align-items-center mb-25">
                                            <p style="cursor: pointer;"
                                                class="badge badge-pill badge-success font-small-5 font-weight-bold line-height-2"
                                                routerLink="/home/details/{{item.ticketId}}">
                                                #{{item.trackingNumber}}
                                            </p>
                                        </div>
                                        <div
                                            *ngIf="item.text == 'undefined' || item.text == '[object Object]' else emptyMessageText">
                                        </div>
                                        <ng-template #emptyMessageText>
                                            <p class="card-text text-left"
                                                [innerHTML]="item.text.length>28? item.text.slice(0,28)+'...':item.text">
                                            </p>
                                        </ng-template>

                                        <span
                                            class="d-flex justify-content-end align-items-center font-small-1 font-weight-bold">
                                            <span *ngIf="item.documentId!=0 " class="mr-2" ng-reflect-placement="top"
                                                placement="top" container="body" ng-reflect-container="body"
                                                ng-reflect-ngb-tooltip="[object Object]"
                                                ngbTooltip={{item.documentUrl}}>
                                                <img src="assets/images/icons/doc.png" alt="data.json" class=""
                                                    height="17"></span>

                                            <span *ngIf="item.ticketStatusId==1 " class="mr-2"
                                                ng-reflect-placement="top" placement="top" container="body"
                                                ng-reflect-container="body" ng-reflect-ngb-tooltip="[object Object]"
                                                ngbTooltip="Yeni Oluşturuldu"><i data-feather="target"
                                                    class="avatar bg-light-info avatar-icon"></i></span>
                                            <span *ngIf="item.ticketStatusId==2" class="mr-2" ng-reflect-placement="top"
                                                placement="top" container="body" ng-reflect-container="body"
                                                ng-reflect-ngb-tooltip="[object Object]"
                                                ngbTooltip="Danışmandan Cevap Bekleniyor"><i data-feather="target"
                                                    class="avatar bg-light-primary avatar-icon"></i></span>
                                            <span *ngIf="item.ticketStatusId==4" class="mr-2" ng-reflect-placement="top"
                                                placement="top" container="body" ng-reflect-container="body"
                                                ng-reflect-ngb-tooltip="[object Object]" ngbTooltip="Tamamlandı"> <i
                                                    data-feather="target"
                                                    class="avatar bg-light-success avatar-icon"></i></span>
                                            <span *ngIf="item.ticketStatusId==5" class="mr-2" ng-reflect-placement="top"
                                                placement="top" container="body" ng-reflect-container="body"
                                                ng-reflect-ngb-tooltip="[object Object]" ngbTooltip="İptal Edildi"><i
                                                    data-feather="target"
                                                    class="avatar bg-light-danger avatar-icon"></i></span>
                                            <span *ngIf="item.ticketStatusId==8" class="mr-2" ng-reflect-placement="top"
                                                placement="top" container="body" ng-reflect-container="body"
                                                ng-reflect-ngb-tooltip="[object Object]"
                                                ngbTooltip="Müşteriden Cevap Bekleniyor"><i data-feather="target"
                                                    class="avatar bg-light-warning avatar-icon"></i></span>
                                            {{item.sendedDate | date:'dd-MM-yyyy HH:mm'}}
                                        </span>
                                    </div>
                                </div>
                                <hr>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <div class="text-center">
                                        <button class="btn btn-group btn-outline-primary"
                                            (click)="previousPageTicketMessages()">Önceki</button>
                                    </div>
                                </div>
                                <div class="col-5"></div>
                                <div class="col-3 mr-1">
                                    <div class="text-center ">
                                        <button class="btn btn-group btn-outline-primary"
                                            (click)="nextPageTicketMessages()">Sonraki</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink (click)="listofUrgentTickets(pageNumberForUrgentTickets)">Acil Talepler</a>
                <ng-template ngbNavContent>
                    <div class="card">
                        <div class="card-body">
                            <div *ngFor="let item of newUrgentTicketUsers">
                                <div class="row">
                                    <div class="col-2 mt-2 ml-3">
                                        <div class="d-flex justify-content-end align-items-center mb-25">
                                            <p style="cursor: pointer;"
                                                class="badge badge-pill badge-danger font-small-5 font-weight-bold line-height-2"
                                                routerLink="/home/details/{{item[0].ticketId}}">
                                                #{{item[0].trackingNumber}}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-3 mt-1">
                                        <div class="d-flex mb-0">
                                            <div *ngFor="let user of item; let i = index"
                                                class="avatar ml-0 pull-up ng-star-inserted"
                                                [ngClass]="{'bg-light-warning': user.typeName=='Sorumlu', 'bg-light-primary': user.typeName=='Takipçi'}"
                                                placement="top">
                                                <div *ngIf="i<2">

                                                    <span *ngIf="user.imageURL==null; else imageUserTemplate"
                                                        class="avatar-content avatar-status ng-star-inserted text-center"
                                                        ng-reflect-placement="top" placement="top" container="body"
                                                        ng-reflect-container="body"
                                                        ng-reflect-ngb-tooltip="[object Object]"
                                                        ngbTooltip="{{user.userFirstName}} {{user.userLastName}}">
                                                        <div>
                                                            {{user.userFirstName.charAt(0)}}{{user.userLastName.charAt(0)}}
                                                        </div>
                                                    </span>
                                                    <ng-template #imageUserTemplate>
                                                        <span
                                                            class="avatar-content avatar-status ng-star-inserted text-center"
                                                            ng-reflect-placement="top" placement="top" container="body"
                                                            ng-reflect-container="body"
                                                            ng-reflect-ngb-tooltip="[object Object]"
                                                            ngbTooltip="{{user.userFirstName}} {{user.userLastName}}">
                                                            <div
                                                                *ngIf="user.typeName!='Sorumlu'; else followersTemplate">
                                                                <div class="avatar" placement="top" style="border: 2px solid rgb(76, 76, 200);
                                                      border-radius: 50%;
                                                      padding: 1px;">
                                                                    <img src="../../../../assets/images/img/{{user.imageURL}}"
                                                                        style="border-radius:50px ;" width="25"
                                                                        height="25" />
                                                                </div>
                                                            </div>
                                                            <ng-template #followersTemplate>
                                                                <div class="avatar" placement="top" style="border: 2px solid orange;
                                                      border-radius: 50%;
                                                      padding: 1px;">
                                                                    <img src="../../../../assets/images/img/{{user.imageURL}}"
                                                                        style="border-radius:50px ;" width="25"
                                                                        height="25" />
                                                                </div>
                                                            </ng-template>
                                                        </span>
                                                    </ng-template>
                                                </div>
                                                <ng-container *ngIf="i===2">
                                                    <div class="avatar pull-up ng-star-inserted bg-light-secondary"
                                                        placement="top">
                                                        <span
                                                            class="avatar-content avatar-status ng-star-inserted text-center"
                                                            ng-reflect-placement="top" placement="top" container="body"
                                                            ng-reflect-container="body"
                                                            ng-reflect-ngb-tooltip="[object Object]"
                                                            ngbTooltip="+{{item.length - 2}} kişi">
                                                            <div>
                                                                +{{item.length - 2}}
                                                            </div>
                                                        </span>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-5">
                                        <p class="card-text text-left"
                                            [innerHTML]="item[0].subject.length>28? item[0].subject.slice(0,28)+'...':item[0].subject">
                                        </p>
                                        <span
                                            class="d-flex justify-content-end align-items-center font-small-1 ml-3 font-weight-bold">
                                            {{item[0].createdDate| date:'dd-MM-yyyy HH:mm'}}

                                        </span>
                                    </div>
                                </div>
                                <hr>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <div class="text-center">
                                        <button class="btn btn-group btn-outline-danger"
                                            (click)="previousPageUrgentTickets(pageNumberForUrgentTickets)">Önceki</button>
                                    </div>
                                </div>
                                <div class="col-5"></div>
                                <div class="col-3 mr-1">
                                    <div class="text-center ">
                                        <button class="btn btn-group btn-outline-danger"
                                            (click)="nextPageUrgentTickets(pageNumberForUrgentTickets)">Sonraki</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-1"></div>
    </div>
    <div class="col-lg-4 col-md-6 col-12">
        <div class="card card-developer-meetup">
            <a href="/home/infoplan">
                <div class="bg-light-primary rounded-top text-center">
                    <img src="assets/images/illustration/email.svg" alt="Meeting Pic" height="170" />
                </div>
            </a>

            <div class="card-body" *ngIf="plan else emptyPlanTemplate">
                <div class="meetup-header d-flex align-items-center">
                    <div class="meetup-day">
                        <h6 class="mb-0" *ngIf="plan.startDate">{{ getTurkishDay(plan.startDate) }}</h6>
                        <h3 class="mb-0">{{ plan.startDate | date: 'dd' }}</h3>
                    </div>
                    <div class="my-auto">
                        <h4 class="card-title mb-25">{{plan.title}} </h4>
                        <p class="card-text mb-0" *ngIf="plan.partnerId">{{partnerName}}</p>
                    </div>

                </div>
                <div class="media">
                    <div class="avatar bg-light-primary rounded mr-1">
                        <div class="avatar-content">
                            <i data-feather="calendar" class="avatar-icon font-medium-3"></i>
                        </div>
                    </div>
                    <div class="media-body">
                        <h6 class="mb-0" *ngIf="plan.startDate">{{ plan.startDate | date:'dd' }} {{
                            getTurkishMonth(plan.startDate) }}, {{ plan.startDate | date:'yyyy' }}</h6>
                        <small *ngIf="plan.endDate">
                            {{ plan.endDate | date:'dd' }} {{getTurkishMonth(plan.endDate) }}  {{ getTurkishDay(plan.endDate) }} ,{{plan.endDate|date:'HH:mm'}} Kadar</small>
                    </div>
                </div>
                <div class="media">
                    <div class="avatar bg-light-primary rounded mr-1">
                        <div class="avatar-content">
                            <i data-feather="feather" class="avatar-icon font-medium-3"></i>
                        </div>
                    </div>
                    <div class="media-body">
                        <h6 class="mb-0">{{plan.planTypeName}}</h6>
                        <small *ngIf="plan.description">{{plan.description.length >50?
                            plan.description.slice(0,50) +'...' : plan.description}}</small>
                    </div>
                </div>
            </div>
            <ng-template #emptyPlanTemplate>
                <div class="meetup-header d-flex align-items-center">
                    <div class="meetup-day">
                        <h6 class="mb-0"></h6>
                        <h3 class="mb-0"></h3>
                    </div>
                    <div class="my-auto">
                        <h6 class="card-title mb-25 mt-25 ">Burası Boş! Henüz Yaklaşan Bir Planın Yok</h6>
                    </div>

                </div>

            </ng-template>
        </div>
    </div>
    <div class="col-lg-4 col-md-12 col-12">
        <div class="card card-transaction">
            <div class="card-header">
                <h4 class="card-title">{{currentMonth}} Ayı Planlı Günleri</h4>
            </div>
            <div class="card-body">
                <div class="transaction-item">
                    <div class="media">
                        <div class="avatar bg-light-danger rounded">
                            <div class="avatar-content">
                                <img src="assets/images/icons/toolbox.svg" alt="Toolbar svg" />
                            </div>
                        </div>
                        <div class="media-body mt-1">
                            <h6>Destek Planı</h6>
                        </div>
                    </div>
                    <div class="font-weight-bolder">{{plannedDays[0]}} Gün</div>
                </div>
                <div class="transaction-item">
                    <div class="media">
                        <div class="avatar bg-light-primary rounded">
                            <div class="avatar-content">
                                <img src="assets/images/icons/speaker.svg" alt="Speaker svg" />
                            </div>
                        </div>
                        <div class="media-body mt-1">
                            <h6 class="transaction-title">Proje Planı</h6>
                        </div>
                    </div>
                    <div class="font-weight-bolder">{{plannedDays[1]}} Gün</div>
                </div>
                <div class="transaction-item">
                    <div class="media">
                        <div class="avatar bg-light-success rounded">
                            <div class="avatar-content">
                                <img src="assets/images/icons/parachute.svg" alt="Parachute svg" />
                            </div>
                        </div>
                        <div class="media-body mt-1">
                            <h6 class="transaction-title">İzin Günleri</h6>
                        </div>
                    </div>
                    <div class="font-weight-bolder">{{plannedDays[2]}} Gün</div>
                </div>
                <div class="transaction-item">
                    <div class="media">
                        <div class="avatar bg-light-info rounded">
                            <div class="avatar-content">
                                <img src="assets/images/icons/star.svg" alt="Star svg" />
                            </div>
                        </div>
                        <div class="media-body mt-1">
                            <h6 class="transaction-title">Resmi Tatiller</h6>
                        </div>
                    </div>
                    <div class="font-weight-bolder">{{plannedDays[3]}} Gün</div>
                </div>

            </div>
        </div>
        <div class="card earnings-card">
            <div class="card-body">
                <div class="row">
                    <div class="col-6">
                        <h4 class="card-title mb-1">Talepler</h4>
                        <div class="font-small-3">Bu Ay</div>

                        <p class="card-text font-small-3">
                            <span [ngClass]="ticketCounts[1]<=0 ? 'text-danger' : 'text-success'"
                                class="font-weight-bolder">{{ticketCounts[1]}}</span><span> Oranında Geçen Aya
                                Göre
                                Değişim
                                Görülüyor</span>
                        </p>
                    </div>
                    <div class="col-6">
                        <div id="earnings-donut-chart" #earningChartRef>
                            <apx-chart [chart]="{
              type: 'donut',
              width:
                isMenuToggled === false ? earningChartRef.offsetWidth : earningChartoptions.chart.width,
              height: 100,
              toolbar: {
                show: false
              }
            }" [dataLabels]="earningChartoptions.dataLabels" [series]="earningChartoptions.series"
                                [legend]="earningChartoptions.legend" [labels]="earningChartoptions.labels"
                                [stroke]="earningChartoptions.stroke" [colors]="earningChartoptions.colors"
                                [grid]="earningChartoptions.grid" [plotOptions]="earningChartoptions.plotOptions">
                            </apx-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        <div class="card card-transaction">
            <div class="card-header">
                <h5>🎂 Yaklaşan Doğum Günleri 🎂</h5>
            </div>
            <div class="card-body">
                <div *ngFor="let item of allBirthdays">
                    <div class="transaction-item">
                        <div class="media">
                            <div>
                                <div style="margin-bottom:3px;">
                                    <div class="media" style="display: flex; align-items: center;">
                                        <div class="avatar bg-light-success" style="margin-right: 10px;">
                                            <div class="avatar-content" style="font-size: xx-small; padding: 15px;">
                                                {{ getInitials(item.firstName + " " + item.lastName) }}
                                            </div>
                                        </div>
                                        <span style="display: flex; align-items: center; font-size: 12px;">
                                            {{ item.firstName }} {{ item.lastName }}
                                        </span>
                                    </div>
                                </div>     
                            </div>
                        </div>
                        <div style="font-size: 12px; font-weight: bold;">{{item.birthdayDate | date:'dd.MM.yyyy'}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>