import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Partner } from 'app/evo/models/partner';
import { User } from 'app/evo/models/user';
import { UserList } from 'app/evo/models/userlist';
import { UserType } from 'app/evo/models/usertype';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoPartnerService } from 'app/evo/services/evo-customer.service';
import { EvoTransactionService } from 'app/evo/services/evo-transaction.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';

@Component({
  selector: 'app-evo-user-list',
  templateUrl: './evo-user-list.component.html',
  styles: [
  ]
})
export class EvoUserListComponent implements OnInit {

  constructor(private userService: EvoUserService,
    private partnerService: EvoPartnerService,
    private transactionService: EvoTransactionService,
    
    private errorService: ErrorService,
    private informationService: InformationService,
    private helperService: HelperService,
    private decodeService: DecodeService,
    private router: Router
  ) { }

  public basicSelectedOption: number = 10;
  myId: number;
  users: UserList[];
  userTypes: UserType[] = [
    { id: 1, name: "İç Kaynak" },
    { id: 2, name: "Dış Kaynak" },
  ];

  selectedUserTypes: UserType[] = [];

  filterText: string = "";
  deletedUserId: number;

  consultantActivityFilters : any[]=[
    {label:'Aktif',value:"Aktif"},{label:"Pasif",value:"Pasif"}]

  selectedConsultantActivityFilter:any[]=["Aktif"];
  ngOnInit(): void {
    this.myId = this.decodeService.getUserId();
    let item = this.decodeService.getRole();
    if (item != "Admin") {
      this.router.navigate(['/home']);
      this.transactionService.saveTransaction(0, 13, 5, this.myId).subscribe((res: any) => {
      });
    }
    else {
      this.list();
    }
  }

  list() {
    this.userService.getConsultantList().subscribe((items: any) => {
      this.users = items.data;
      this.informationService.list(items.message)
    }, (err) => {
      this.errorService.errorHandler(err);
    });

  }

  exportExcel() {
    let element = document.getElementById("excel-table");
    let title = "Danışmanlar";
    this.helperService.exportExcel(element, title);
  }


  tableByFiltersId() {

    if(this.selectedConsultantActivityFilter.length==2 || this.selectedConsultantActivityFilter.length==0){
        this.userService.getAllUserList().subscribe((res:any)=>{
          let filteredItems = res.data;
          if (this.selectedUserTypes.length > 0) {
            filteredItems = filteredItems.filter(item => this.selectedUserTypes.includes(item.typeId));
          }
          this.users = filteredItems;

        })
    }
    else if(this.selectedConsultantActivityFilter.length==1 ){
        if(this.selectedConsultantActivityFilter[0] == "Aktif"){
            this.userService.getActiveUserList().subscribe((res:any)=>{
              let filteredItems = res.data;
              if (this.selectedUserTypes.length > 0) {
                filteredItems = filteredItems.filter(item => this.selectedUserTypes.includes(item.typeId));
              }
              this.users = filteredItems;

            })
        }
        else{
            this.userService.getPassiveUserList().subscribe((res:any)=>{
              let filteredItems = res.data;
              if (this.selectedUserTypes.length > 0) {
                filteredItems = filteredItems.filter(item => this.selectedUserTypes.includes(item.typeId));
              }
              this.users = filteredItems;
            })
        }
    }

  }

  delete(id: number) {
    this.userService.get(id).subscribe((item: any) => {
      item.data.deletedBy = this.myId;
      item.data.updatedBy = 0;
      item.data.password = "";
      this.userService.delete(item.data).subscribe((item: any) => {
        this.informationService.delete(item.message)
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        setTimeout(() => {
          this.list();
        }, 1500);
      });
    });
  }

}
