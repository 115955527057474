import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Competence } from '../models/competence';
import { CompetenceModules } from '../models/competencemodules';

@Injectable({
  providedIn: 'root'
})
export class EvoCompetenceService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getList():Observable<Competence[]>{
    return this.httpClient.get<Competence[]>(this.baseApiUrl+'GetCompetenceList');
  }

  getCompetenceModuleList():Observable<CompetenceModules[]>{
    return this.httpClient.get<CompetenceModules[]>(this.baseApiUrl+'GetCompetenceModuleList');
  }

  getListByUserId(id:number):Observable<Competence[]>{
    return this.httpClient.get<Competence[]>(this.baseApiUrl+'GetCompetenceListByUserId/'+id);
  }

  get (id: number):Observable <Competence> {
    return this.httpClient.get<Competence>(this.baseApiUrl+'GetCompetence/'+id);
  }

  add(competence:Competence):Observable<Competence>{
    return this.httpClient.post<Competence>(this.baseApiUrl+'AddCompetence/',competence);
  }

  update(competence:Competence):Observable<Competence>{
    return this.httpClient.post<Competence>(this.baseApiUrl+"UpdateCompetence/",competence);
  }

  delete(id:number):Observable<Competence>{
    return this.httpClient.post<Competence>(this.baseApiUrl+"DeleteCompetence/"+ id,id);
  }

  addCompetenceModuleInBulk(competenceNames:CompetenceModules[]):Observable<any[]>{
    return this.httpClient.post<any[]>(this.baseApiUrl+"AddCompetenceModuleInBulk/",competenceNames);
  }

  updateCompetenceModule(competenceModule:CompetenceModules):Observable<any[]>{
    return this.httpClient.post<any[]>(this.baseApiUrl+"UpdateCompetenceModule/",competenceModule);
  }

  deleteCompetenceModule(id:number):Observable<any[]>{
    return this.httpClient.post<any[]>(this.baseApiUrl+"DeleteCompetenceModule/"+id,id);
  }

}
