import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Gender } from 'app/evo/models/gender';
import { Partner } from 'app/evo/models/partner';
import { Register } from 'app/evo/models/register';
import { UserType } from 'app/evo/models/usertype';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoPartnerService } from 'app/evo/services/evo-customer.service';
import { EvoTransactionService } from 'app/evo/services/evo-transaction.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { InformationService } from 'app/evo/services/information.service';

@Component({
  selector: 'app-evo-user-add-form',
  templateUrl: './evo-user-add-form.component.html',
  styles: [
  ]
})
export class EvoUserAddFormComponent implements OnInit {
  public passwordTextTypeNew = false;

  constructor(
    private userService: EvoUserService,
    private partnerService: EvoPartnerService,
    private transactionService: EvoTransactionService,

    private formBuilder: FormBuilder,
    private router: Router,
    private informationService: InformationService,
    private errorService: ErrorService,
    private decodeService: DecodeService
  ) { }
  myId: number = 0;
  user: Register = new Register();
  gender: Gender = new Gender();
  userType: UserType = new UserType();
  supplier: Partner = new Partner();

  suppliers: Partner[] = [];

  userTypes: UserType[] = [
    { id: 1, name: "İç Kaynak" },
    { id: 2, name: "Dış Kaynak" }
  ]
  genders: Gender[] = [
    { id: 1, name: "Erkek" },
    { id: 2, name: "Kadın" }
  ]
  userForm: FormGroup;

  ngOnInit(): void {
    let item = this.decodeService.getRole();
    this.myId = this.decodeService.getUserId();
    if (item != "Admin") {
      this.router.navigate(['/home']);
      this.transactionService.saveTransaction(0, 13, 5, this.myId).subscribe((res: any) => {
      });
    }
    else {
      this.userType.id = 1;
      this.partnerService.getSupplierList().subscribe((item: any) => {
        this.suppliers = item.data;
      });
    }
    this.userForm = this.formBuilder.group({
      txtFirstName: ['', Validators.required],
      txtLastName: ['', Validators.required],
      txtTitle: ['', Validators.required],
      txtUserName: ['', Validators.required],
      txtPhoneNumber: ['', Validators.required],
      txtMailAddress: ['', [Validators.required, Validators.email]],
      txtDiscord:[''],
      txtGitlab:[''],
      txtStartDate:[''],
      slcUserType: ['', Validators.required],
      slcSupplier: [''],
      slcGender: ['', Validators.required],
      txtVacationCount: ['',],
      txtPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/)]],
    });
    this.userForm.get('slcUserType').valueChanges.subscribe((userType) => {
      if (userType === 2) {
        this.userForm.get('slcSupplier').setValidators([Validators.required]);
      } else {
        this.userForm.get('slcSupplier').clearValidators();
      }
      this.userForm.get('slcSupplier').updateValueAndValidity();
    });
  }
  add() {

    if (this.userForm.value.slcUserType == 1) {
      this.userForm.value.slcSupplier = null;
    }
    this.user = {
      firstName: this.userForm.value.txtFirstName,
      lastName: this.userForm.value.txtLastName,
      title: this.userForm.value.txtTitle,
      userName: this.userForm.value.txtUserName,
      phoneNumber: this.userForm.value.txtPhoneNumber,
      mailAddress: this.userForm.value.txtMailAddress,
      password: this.userForm.value.txtPassword,
      genderId: this.userForm.value.slcGender,
      gitlab : this.userForm.value.txtGitlab,
      discord:this.userForm.value.txtDiscord,
      birthdayDate:this.userForm.value.txtStartDate,
      roleId: 1,
      partnerId: this.userForm.value.slcSupplier,
      typeId: this.userForm.value.slcUserType,
      vacationCount:0,
      createdBy: this.myId,
    };
    if(this.userForm.value.vacationCount != null){
      this.user.vacationCount = this.userForm.value.vacationCount
    }
    this.userService.register(this.user).subscribe((item: any) => {
      this.informationService.add(item.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.router.navigate(["/home/users"]);
      }, 100);
    });
  }


  togglePasswordTextTypeNew() {
    this.passwordTextTypeNew = !this.passwordTextTypeNew;
  }
}
