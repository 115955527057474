import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items || !searchText) {
      return items;
    }
    
    searchText = searchText.toLocaleLowerCase('tr-TR').trim();
    
    return items.filter(item => 
      Object.values(item).some(val => 
        typeof val === 'string' && val.toLocaleLowerCase('tr-TR').includes(searchText)
      )
    );
  }
}