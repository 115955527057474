import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Gender } from 'app/evo/models/gender';
import { OperationClaim } from 'app/evo/models/operationclaim';
import { Partner } from 'app/evo/models/partner';
import { Permission } from 'app/evo/models/permission';
import { PermissionUser } from 'app/evo/models/permissionuser';
import { User } from 'app/evo/models/user';
import { UserOpertaionClaim } from 'app/evo/models/useroperationclaim';
import { UserType } from 'app/evo/models/usertype';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoPartnerService } from 'app/evo/services/evo-customer.service';
import { EvoOperationClaimService } from 'app/evo/services/evo-operationclaim.service';
import { EvoPermissionUserService } from 'app/evo/services/evo-permission-user.service';
import { EvoPermissionService } from 'app/evo/services/evo-permission.service';
import { EvoProjectService } from 'app/evo/services/evo-project.service';
import { EvoTransactionService } from 'app/evo/services/evo-transaction.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { EvoUseroperationclaimService } from 'app/evo/services/evo-useroperationclaim.service';
import { InformationService } from 'app/evo/services/information.service';

@Component({
  selector: 'app-evo-user-update-form',
  templateUrl: './evo-user-update-form.component.html',
  styles: [
  ]
})
export class EvoUserUpdateFormComponent implements OnInit {

  user: User = new User();
  gender: Gender = new Gender();
  userType: UserType = new UserType();
  parnter: Partner = new Partner();
  permission: Permission = new Permission();
  permissionUser: PermissionUser = new PermissionUser();

  partners: Partner[] = [];
  suppliers: Partner[] = [];
  permissions: Permission[] = [];
  permissionUsers: PermissionUser[] = [];

  userTypes: UserType[] = [
    { id: 1, name: "İç Kaynak" },
    { id: 2, name: "Dış Kaynak" }
  ]
  genders: Gender[] = [
    { id: 1, name: "Erkek" },
    { id: 2, name: "Kadın" }
  ];

  operationClaims: OperationClaim[];
  userOperationClaims: UserOpertaionClaim[];
  operationClaim: OperationClaim = new OperationClaim();
  userOperationClaim: UserOpertaionClaim = new UserOpertaionClaim();

  cantSeeAllTickets: boolean = false;
  canSeeMyTickets: boolean = false;
  cantSeeAllActivities: boolean = false;
  canSeeMyActivities: boolean = false;
  cantSeeAllEfforts: boolean = false;
  canSeeMyEfforts: boolean = false;
  cantSeeAllPartners: boolean = false;
  cantSeeAllExpenses: boolean = false;
  cantSeeAllGoals: boolean = false;
  exemptFromNotifications:boolean=false;

  AllTickets: boolean = false;
  MyTickets: boolean = false;
  AllActivities: boolean = false;
  MyActivities: boolean = false;
  AllEfforts: boolean = false;
  MyEfforts: boolean = false;
  AllPartners: boolean = false;
  AllExpenses: boolean = false;
  AllGoals: boolean = false;
  AllNotifications:boolean=false;

  constructor(
    private userService: EvoUserService,
    private partnerService: EvoPartnerService,
    private permissionService: EvoPermissionService,
    private permissionUserService: EvoPermissionUserService,
    private transactionService: EvoTransactionService,

    private route: ActivatedRoute,
    private router: Router,
    private decodeService: DecodeService,
    private informationService: InformationService,
    private userOperationClaimService: EvoUseroperationclaimService,
    private operationClaimService: EvoOperationClaimService,
    private errorService: ErrorService,
    private projectService : EvoProjectService
  ) { }

  id: number;
  myId: number;
  deleteUserOperationClaimId: number;
  basicSelectedOption: number = 10;
  projects : any[] = []
  ngOnInit(): void {
    let item = this.decodeService.getRole();
    this.myId = this.decodeService.getUserId();
    this.id = this.route.snapshot.params['Id'];
    this.projectService.GetProjectExpenseList(this.id).subscribe(response=>{
      this.projects = response.data
    })
    if (item != "Admin") {
      this.router.navigate(['/home']);
      this.transactionService.saveTransaction(this.id, 13, 5, this.myId).subscribe((res: any) => {
      });
    }
    else {
      this.list(this.id);
      this.getPermissions();
      this.getOperaionClaim();
    }
  }

  deleteProjectExpensePermission( projectId){
    this.projectService.deleteProjectExpensePermission(this.id, projectId).subscribe(next=>{
      this.projectService.GetProjectExpenseList(this.id).subscribe(res=>{
        this.projects = res.data
      })
    })
  }
  list(id: number) {
    this.userOperationClaimService.getListByUserId(id).subscribe((items: any) => {
      this.userOperationClaims = items.data;
      this.informationService.list(items.message)
    }, (err) => {
      this.errorService.errorHandler(err);
    });
  }

  getPermissions() {
    this.permissionService.getList().subscribe((items: any) => {
      this.permissions = items.data;
    });
    this.permissionUserService.getPermissionUserListByUserId(this.id).subscribe((items: any) => {
      this.permissionUsers = items.data;
      this.getPermissionsMark(this.permissionUsers);
    });
  }

  getPermissionsMark(permissionUsers: any) {
    permissionUsers.forEach((item) => {
      if (item.permissionId == 1) {
        this.cantSeeAllTickets = true;
      }
      if (item.permissionId == 2) {
        this.canSeeMyTickets = true;
      }
      if (item.permissionId == 5) {
        this.cantSeeAllActivities = true;
      }
      if (item.permissionId == 6) {
        this.canSeeMyActivities = true;
      }
      if (item.permissionId == 8) {
        this.cantSeeAllEfforts = true;
      }
      if (item.permissionId == 9) {
        this.canSeeMyEfforts = true;
      }
      if (item.permissionId == 10) {
        this.cantSeeAllPartners = true;
      }
      if (item.permissionId == 11) {
        this.cantSeeAllExpenses = true;
      }
      if (item.permissionId == 12) {
        this.cantSeeAllGoals = true;
      }
      if (item.permissionId == 13) {
        this.exemptFromNotifications = true;
      }
    });
    this.AllTickets = this.cantSeeAllTickets;
    this.MyTickets = this.canSeeMyTickets;
    this.AllActivities = this.cantSeeAllActivities;
    this.MyActivities = this.canSeeMyActivities;
    this.AllEfforts = this.cantSeeAllEfforts;
    this.MyEfforts = this.canSeeMyEfforts;
    this.AllPartners = this.cantSeeAllPartners;
    this.AllExpenses = this.cantSeeAllExpenses;
    this.AllGoals = this.cantSeeAllGoals;
    this.AllNotifications = this.exemptFromNotifications;
  }


  formatDateForInput(date: Date): string {
    // Format date as YYYY-MM-DDTHH:mm
    const pad = (n: number) => n < 10 ? '0' + n : n;
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
  }
  
  getOperaionClaim() {
    this.operationClaimService.getList().subscribe((res: any) => {
      this.operationClaims = res.data;
    });
    this.userService.get(this.id).subscribe((item: any) => {
      this.user = item.data;
      if(this.user.birthdayDate != null){
        this.user.birthdayDate=this.formatDateForInput(new Date(this.user.birthdayDate));

      }
    });
    this.partnerService.getList().subscribe((items: any) => {
      this.partners = items.data;
    });
    this.partnerService.getSupplierList().subscribe((items: any) => {
      this.suppliers = items.data;
    });
  }
  slcParnerIdPEP;
  PEPlist = []; //ProjectExpensePermission List
  changeProjectWithPartner(){
    console.log(this.slcParnerIdPEP)
    this.projectService.getListofContinuingByPartnerId(this.slcParnerIdPEP).subscribe((res:any)=>{
      this.PEPlist = res.data
    })
  }
  deletePEPid;

  selectPRPId;
  addPEP(){
    let projectExpensePermission : any = {}
    projectExpensePermission.userId = this.id
    projectExpensePermission.projectId = this.selectPRPId
    projectExpensePermission.createdBy = this.myId
    projectExpensePermission.isActive = true
    console.log(projectExpensePermission)
    if(this.selectPRPId >=0){
      this.projectService.AddProjectExpensePermission(projectExpensePermission).subscribe(res=>{
        this.projectService.GetProjectExpenseList(this.id).subscribe(response=>{
          this.projects = response.data
          console.log(response.data)
        })
        console.log(res)
      })
    }
  }
  update() {
    let flag = 0;

    if(this.user.password == undefined || this.user.password==""){
      this.user.password = null;
    }
    
    if (this.user.partnerId == 0) {
      flag = 1;
    }
    if (flag == 0) {
      this.user.updatedBy = this.myId;
      this.user.deletedBy = 0;
      this.userService.update(this.user).subscribe((item: any) => {
        this.informationService.update(item.message);
        this.addPermissionUser();
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        setTimeout(() => {
          this.ngOnInit();
        }, 500);
      });
    }
    else if (flag == 1) {
      this.informationService.warning("Dış Kaynak Kullanıcılar İçin Firma Seçilmelidir!");
    }
  }
  getUserOperationClaim(id: number) {
    this.userOperationClaimService.get(id).subscribe((item: any) => {
      this.userOperationClaim = item.data;
    });
  }

  addPermissionUser() {
    const permissions = [
      { id: 1, current: this.AllTickets, new: this.cantSeeAllTickets },
      { id: 2, current: this.MyTickets, new: this.canSeeMyTickets },
      { id: 5, current: this.AllActivities, new: this.cantSeeAllActivities },
      { id: 6, current: this.MyActivities, new: this.canSeeMyActivities },
      { id: 8, current: this.AllEfforts, new: this.cantSeeAllEfforts },
      { id: 9, current: this.MyEfforts, new: this.canSeeMyEfforts },
      { id: 10, current: this.AllPartners, new: this.cantSeeAllPartners },
      { id: 11, current: this.AllExpenses, new: this.cantSeeAllExpenses },
      { id: 12, current: this.AllGoals, new: this.cantSeeAllGoals },
      { id: 13, current: this.AllNotifications, new: this.exemptFromNotifications }

    ];

    for (const permission of permissions) {
      if (permission.current !== permission.new) {
        const permissionToUpdate = this.permissionUsers.filter(item => item.permissionId === permission.id);

        if (!permission.new) {
          this.deletePermissionUser(permissionToUpdate);
        } else {
          this.addPermissionUserByPermissionId(permission.id);
        }
      }
    }
  }

  addPermissionUserByPermissionId(permissionId: number) {
    this.permissionUser = {
      id: 0,
      permissionId: permissionId,
      userId: this.id,

      createdBy: this.myId,
      updatedBy: 0,
      deletedBy: 0,
    };
    this.permissionUserService.add(this.permissionUser).subscribe((item: any) => {
      this.informationService.add(item.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.ngOnInit();
      }, 500);
    });
  }
  deletePermissionUser(permissionUsers: any) {
    permissionUsers.forEach((item) => {
      item.deletedBy = this.myId;
      this.permissionUserService.delete(item).subscribe((item: any) => {
      });
    });
  }

  addUserOperationClaim() {
    let flag = 0;

    if (this.userOperationClaims.length >= 1) {
      flag = 2;
      for (let item = 0; item < this.userOperationClaims.length; item++) {
        if (this.userOperationClaims[item].operationClaimId == this.operationClaim.id) {
          flag = 1;
        }
      }
    }
    if (flag == 0) {
      this.userOperationClaim = {
        id: 0,
        userId: this.id,
        operationClaimId: this.operationClaim.id,
        userName: "",
        operationClaimTitle: "",
        createdBy: this.myId,
        updatedBy: 0,
        deletedBy: 0,
      };
      this.userOperationClaimService.add(this.userOperationClaim).subscribe((res: any) => {
        this.informationService.add(res.message)
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        setTimeout(() => {
          this.ngOnInit();
        }, 500);
      });
    }
    if (flag == 1) {
      this.informationService.warning("Kullanıcı Yetkiye Zaten Sahip!");
    }
    if (flag == 2) {
      this.informationService.warning("Kullanıcı En Fazla 1 Danışman Yetkisine Sahip Olabilir!");
    }
  }
  updateUserOperationClaim() {
    this.userOperationClaim.updatedBy = this.myId;
    this.userOperationClaim.deletedBy = 0;
    this.userOperationClaimService.update(this.userOperationClaim).subscribe((item: any) => {
      this.informationService.update(item.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.ngOnInit();
      }, 500);
    });
  }
  deleteUserOperationClaim(id: number) {
    if (this.userOperationClaims.length == 1) {
      this.errorService.errorHandler("Kullanıcının En Az Bir Tane Rolü Olmalı");
    }
    this.userOperationClaimService.get(id).subscribe((item: any) => {
      item.data.deletedBy = this.myId;
      item.data.updatedBy = 0;

      this.userOperationClaimService.delete(item.data).subscribe((item: any) => {
        this.informationService.delete(item.message)
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      });
    });
  }

  changeUserType() {
    if (this.user.typeId == 1) {
      this.user.partnerId = null;
      this.cantSeeAllTickets = false;
      this.cantSeeAllActivities = false;
      this.cantSeeAllEfforts = false;
    }
    if (this.user.typeId == 2) {
      this.user.partnerId = 0;
      this.cantSeeAllTickets = true;
      this.cantSeeAllActivities = true;
      this.cantSeeAllEfforts = true;
    }
  }
}

