<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="page-account-settings">
            <div class="row nav-vertical d-flex">
                <div class="col-md-3 mb-2 mb-md-0">
                    <ul ngbNav #navVertical="ngbNav" class="nav nav-pills flex-column nav-left">
                        <li class="nav-item" ngbNavItem>
                            <a ngbNavLink class="nav-link d-flex py-75" id="account-pill-general" data-toggle="pill"
                                href="#account-vertical-general" aria-expanded="true">
                                <i data-feather="user" class="font-medium-3 mr-1"></i>
                                <span class="font-weight-bold">Genel Bilgiler</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">
                                        <div class="media">
                                            <a href="javascript:void(0);" class="mr-25">
                                                <img src="../../../../assets/images/img/{{user.imageURL}}"
                                                    class="rounded mr-50" alt="profile image" height="76" width="80" />
                                            </a>
                                            <div class="media-body mt-75 ml-1">
                                                <fieldset class="form-group">
                                                    <label for="file-upload-multiple">Profil Resmi Seçin</label>
                                                    <div class="custom-file">
                                                        <input class="custom-file-input" type="file"
                                                            (change)="getDocument($event)" #document
                                                            id="file-upload-multiple" />
                                                        <label class="custom-file-label">Dosya Seçiniz</label>
                                                    </div>
                                                </fieldset>
                                                <button (click)="uploadImage()" for="account-upload"
                                                    class="btn btn-sm btn-primary" rippleEffect>
                                                    Yükle
                                                </button>
                                                <br>
                                                <br>
                                                <p>Sadece PNG,JPG,JPEG Formatında Ve Maksiumum 5Mb Büyüklüğünde</p>
                                            </div>
                                        </div>

                                        <form class="validate-form mt-2">
                                            <div class="row">
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="account-username">Kullanıcı Adı:</label>
                                                        <input type="text" class="form-control" id="account-username"
                                                            name="txtUserName" [(ngModel)]="user.userName"
                                                            #txtUserName="ngModel" placeholder="Username" />
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="account-username">Kullanıcı Başlığı:</label>
                                                        <input type="text" class="form-control" id="account-username"
                                                            name="txtTitle" [(ngModel)]="user.title" #txtTitle="ngModel"
                                                            placeholder="Username" />
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="account-name">Adı:</label>
                                                        <input type="text" class="form-control" name="txtFirstName"
                                                            [(ngModel)]="user.firstName" #txtFirstName="ngModel"
                                                            placeholder="Name" />
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="account-name">Soyadı:</label>
                                                        <input type="text" class="form-control" name="txtLastName"
                                                            [(ngModel)]="user.lastName" #txtLastName="ngModel"
                                                            placeholder="Name" />
                                                    </div>
                                                </div>

                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="account-e-mail">Mail Adresi:</label>
                                                        <input type="email" class="form-control" id="account-e-mail"
                                                            name="txtMailAddress" [(ngModel)]="user.mailAddress"
                                                            #txtMailAddress="ngModel" placeholder="Email" />
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="account-company">Telefon Numarası:</label>
                                                        <input type="text" class="form-control" id="account-company"
                                                            name="txtPhoneNumber" [(ngModel)]="user.phoneNumber"
                                                            #txtPhoneNumber="ngModel" placeholder="Telefon Numarası" />
                                                    </div>
                                                </div>

                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="account-company">Doğum Günü:</label>
                                                        <input type="date" class="form-control" id="account-birthdayDate"
                                                            name="dtBirthdayDate" [(ngModel)]="user.birthdayDate"
                                                            #dtBirthdayDate="ngModel" />
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="account-company">Gitlab Adresi:</label>
                                                        <input type="text" class="form-control" id="account-company"
                                                            name="txtGitlab" [(ngModel)]="user.gitlab"
                                                            #txtPhoneNumber="ngModel" placeholder="Gitlab Adresi" />
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="account-company">Discord Adresi:</label>
                                                        <input type="text" class="form-control" id="account-company"
                                                            name="txtDiscord" [(ngModel)]="user.discord"
                                                            #txtPhoneNumber="ngModel" placeholder="Discord Adresi" />
                                                    </div>
                                                </div>

                                                <div class="col-12">
                                                    <button type="button" class="btn btn-success mt-2"
                                                        (click)="updateMyProfile()" rippleEffect>Kaydet</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li class="nav-item" ngbNavItem>
                            <a ngbNavLink class="nav-link d-flex py-75" id="account-pill-password" data-toggle="pill"
                                href="" aria-expanded="false">
                                <i data-feather="lock" class="font-medium-3 mr-1"></i>
                                <span class="font-weight-bold">Şifre Bilgileri</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">
                                        <form class="validate-form">
                                            <div class="row">
                                                <div class="col-12 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="account-old-password">Eski Şifre:</label>
                                                        <div class="input-group form-password-toggle input-group-merge">
                                                            <input [type]="passwordTextTypeOld ? 'text' : 'password'"
                                                                class="form-control" id="account-old-password"
                                                                name="txtOldPassowrd" placeholder="Eski Şifre"
                                                                 #txtOldPassowrd />
                                                            <div class="input-group-append">
                                                                <span class="input-group-text cursor-pointer"><i
                                                                        class="feather font-small-4" [ngClass]="{
                                        'icon-eye-off': passwordTextTypeOld,
                                        'icon-eye': !passwordTextTypeOld
                                      }" (click)="togglePasswordTextTypeOld()"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="account-new-password">Yeni Şifre</label>
                                                        <div class="input-group form-password-toggle input-group-merge">
                                                            <input [type]="passwordTextTypeNew ? 'text' : 'password'"
                                                                id="account-new-password" name="txtNewPassowrd"
                                                                class="form-control" placeholder="Yeni Şifre"
                                                                #txtNewPassowrd />
                                                            <div class="input-group-append">
                                                                <span class="input-group-text cursor-pointer"><i
                                                                        class="feather font-small-4" [ngClass]="{
                                        'icon-eye-off': passwordTextTypeNew,
                                        'icon-eye': !passwordTextTypeNew
                                      }" (click)="togglePasswordTextTypeNew()"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <button type="button" class="btn btn-success mr-1 mt-1"
                                                        (click)="passwordCheck(txtOldPassowrd.value,txtNewPassowrd.value)"
                                                        rippleEffect>Kaydet</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                </div>
                <div [ngbNavOutlet]="navVertical" class="col-12 col-md-9"></div>
            </div>
        </section>
    </div>
</div>