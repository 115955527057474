import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { BillType } from 'app/evo/models/billtype';
import { PartnerUser } from 'app/evo/models/partneruser';
import { Department } from 'app/evo/models/department';
import { Priority } from 'app/evo/models/priority';
import { Project } from 'app/evo/models/project';
import { Ticket } from 'app/evo/models/ticket';
import { TicketType } from 'app/evo/models/tickettype';
import { TicketUser } from 'app/evo/models/ticketuser';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoPartnerUserService } from 'app/evo/services/evo-customer-user.service';
import { EvoDepartmentService } from 'app/evo/services/evo-department.service';
import { EvoProjectService } from 'app/evo/services/evo-project.service';
import { EvoTicketService } from 'app/evo/services/evo-ticket.service';
import { EvoTicketUserService } from 'app/evo/services/evo-ticketuser.service';
import { InformationService } from 'app/evo/services/information.service';
import * as snippet from '../layout/quill-editor.snippetcode';
import { EvoPartnerService } from 'app/evo/services/evo-customer.service';
import { Partner } from 'app/evo/models/partner';
import { EvoModuleService } from 'app/evo/services/evo-module.service';
import { Module } from 'app/evo/models/module';
import { TicketConnection } from 'app/evo/models/ticketconnection';
import { EvoTicketconnectionService } from 'app/evo/services/evo-ticketconnection.service';
import { FileUploader } from 'ng2-file-upload';
import { environment } from 'environments/environment';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { User } from 'app/evo/models/user';
import Quill from 'quill';
import QuillMention from 'quill-mention';
import { zoomIn } from '@core/animations/core.animation';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { EvoTicketMessageService } from 'app/evo/services/evo-ticketmessage.service';

const URL = environment.apiUrl + 'UploadTicketMessageFile';

@Component({
  selector: 'app-evo-ticket-add-form',
  templateUrl: './evo-ticket-add-form.component.html',
  styles: [
  ],
  providers: [EvoTicketService],
  animations: [zoomIn, trigger('menu', [
    state('enter', style({ opacity: 1, marginTop: '0px', visibility: 'visible' })),
    state('exit, void', style({ opacity: 0, marginTop: '-15px' })),
    transition('* => *', animate('120ms ease-in'))
])]
  
})
export class EvoTicketAddFormComponent implements OnInit {

  quillEditorContent = snippet.quillEditorContent;
  message: string;
  public uploader: FileUploader = null;
  documents: any[] = [];
  userList: User[] = [];
  users: User[] = [];

  myId: number;
  role: string = "";
  domain: string = "";
  selectedProject: number = 0;
  buttonClicked: boolean = true;

  parentTicketId : number;
  ticketConnection : TicketConnection = new TicketConnection();

  isLoading: boolean = false; 
  ticket: Ticket = new Ticket();
  ticketUser: TicketUser = new TicketUser();
  partner: Partner = new Partner();
  partnerUser: PartnerUser = new PartnerUser();
  department: Department = new Department();
  addedDepartment: Department = new Department();
  priority: Priority = new Priority();
  billType: BillType = new BillType();
  type: TicketType = new TicketType();
  project: Project = new Project();
  module: Module = new Module();
  quillEditor: any;
  prevContent = null;

  billTypes: BillType[] = [
    { id: 4, name: "Faturalanmadı" },
    { id: 5, name: "Faturalandı" },
    { id: 6, name: "Feragat Edildi" }
  ];
  ticketTypes: TicketType[] = [
    { id: 1, name: "Destek Talebi" },
    { id: 2, name: "Müşteri Planı" },
    { id: 3, name: "Proje Planı" },
  ];
  priorities: Priority[] = [
    { id: 1, name: "Yüksek" },
    { id: 2, name: "Normal" },
    { id: 3, name: "Düşük" },
  ];

  editorModules = {
    toolbar: [
        ['bold', 'italic', 'underline'],
        [{ 'align': [] }],
        [{ 'list': 'ordered' }],
        ['link'],
        ['formula', 'code-block'],
        ['clean']
    ],
    clipboard: {
        matchers: [],
        dragNDrop: {
            draggableElements: []
        }
    },
    mention: {
        allowedChars: /^[A-Za-z\sÇçĞğİıÖöŞşÜü]*$/,
        mentionDenotationChars: ['@'],
        source: this.mentionSourceCallback.bind(this),
        renderItem: this.renderMentionItem,
        onSelect: (item, insertItem) => {
            this.userService.get(item.id).subscribe((res: any) => {
                this.userList.push(res.data);

            })
            insertItem(item);
        }
    }
};


  departments: Department[];
  partners: Partner[] = [];
  projects: Project[] = [];
  modules: Module[] = [];

  constructor(
    private decodeService: DecodeService,
    private partnerUserService: EvoPartnerUserService,
    private ticketService: EvoTicketService,
    private ticketUserService: EvoTicketUserService,
    private departmentService: EvoDepartmentService,
    private partnerService: EvoPartnerService,
    private projectService: EvoProjectService,
    private moduleService: EvoModuleService,
    private router: Router,
    private informationService: InformationService,
    private errorService: ErrorService,
    private ticketConnectionService : EvoTicketconnectionService,
    private userService:EvoUserService,
    private ticketMessageService:EvoTicketMessageService
  ) { 

  }

  ngOnInit(): void {
    this.domain = window.location.hostname;
    this.myId = this.decodeService.getUserId();
    Quill.register('modules/mention', QuillMention);

    let partnerUserId = this.decodeService.getUserId();
    this.role = this.decodeService.getRole();
    if (this.role == 'Customer' || this.role == 'CustomerUser') {
      this.partnerUserService.getByUserId(partnerUserId).subscribe((res: any) => {
        this.projectService.getListofContinuingByPartnerId(res.data.partnerId).subscribe((res: any) => {
          this.projects = res.data;
        })
      });
    }
    if (this.role == "Admin" || this.role == "Consultant" || this.role == "ConsultantUser") {
      this.partnerService.getList().subscribe((res: any) => {
        this.partners = res.data;
      });
    }
    this.moduleService.getList().subscribe((res: any) => {
      this.modules = res.data;
    });

    this.userService.getListForFilter().subscribe((res: any) => {
      this.users = res.data;

    });
    
    this.listOfDepartments();

    if(localStorage.getItem("TicketId") != null){
      this.parentTicketId = parseInt(localStorage.getItem("TicketId"));
      localStorage.removeItem("TicketId");
    }

    this.configureFileUploader();
  }

  getDocuments(event: any) {
    this.documents.push(this.uploader.queue);
}

  listOfDepartments() {
    this.departmentService.getList().subscribe((items: any) => {
      this.departments = items.data;
    });
  }
  isAddingTicket: boolean = false;


  addMessage(message: string,addedTicketId:number) {
    let flag = 0;

    if(message==null && this.uploader.queue.length==0){
      flag=1;
    }
    if (message != null) {
        if (message.length > 4000) {
            this.informationService.warning("Mesajınız karakter sınırının üzerindedir!");
            flag = 1;
        }
    }


    if (flag == 0) {

        let formData = new FormData();
        formData.append("ticketId", addedTicketId.toString());
        formData.append("userId", this.myId.toString());
        formData.append("text", message);
        for (let i = 0; i < this.uploader.queue.length; i++) {
            formData.append('documents', this.uploader.queue[i]._file)
        }

        for (let i = 0; i < this.userList.length; i++) {
            this.ticketMessageService.sendMessage(addedTicketId, this.userList[i].id).subscribe((res: any) => {
            });
        }

        this.informationService.add("Mesajınız gönderiliyor...");
        this.ticketMessageService.add(formData).subscribe((res: any) => {
            this.informationService.add(res.message);

        }, (err) => {
            this.errorService.errorHandler(550);
        }, () => {
            setTimeout(() => {
                this.message = undefined;
                this.uploader.clearQueue();
            }, 1000);
        });
    }
  }

  add(subject: string, expectedTime: number, partnerTicketNo: string) {
    if (this.isAddingTicket) {
      return;
    }
    this.isAddingTicket = true;

    if (this.project.id == 0) {
      this.informationService.warning("Lütfen Proje Seçiniz");
      this.isAddingTicket = false;
    } else {
      this.ticket = {
        id: 0,
        trackingNumber: ("00000000"),
        subject: subject,
        projectId: this.project.id,
        projectName: "",
        priorityId: this.priority.id,
        statusId: 1,
        departmentId: this.department.id,
        typeId: this.type.id,
        typeName: "",
        billTypeId: 4,
        billTypeName: "",
        answerTypeId: 2,
        answerTypeName: "",
        expectedTime: expectedTime || 0,
        departmentName: "",
        partnerId: 0,
        partnerName: "",
        moduleId: this.module.id,
        moduleName: "",
        partnerTicketNo: partnerTicketNo,
        createdBy: this.myId,
        createdDate: new Date(),
        createdByUserName: "",

        updatedBy: 0,
        deletedBy: 0,
      };
      this.ticketService.add(this.ticket).subscribe((item: any) => {
        this.informationService.add(item.message);
        this.isAddingTicket = false;
        let ticketId = item.data.id;

        this.addMessage(this.message,ticketId);
        
        if(this.parentTicketId != null ){
            this.ticketConnection.ticketId = this.parentTicketId
            this.ticketConnection.connectedTicketId = ticketId;
            this.ticketConnection.createdBy = this.myId
            this.ticketConnectionService.add(this.ticketConnection).subscribe((res:any)=>{
              localStorage.removeItem("TicketId")
            })
        }
        setTimeout(() => {
          localStorage.setItem("NewlyAdded","true");
          this.router.navigate(["/home/details/" + ticketId]);
        }, 1500);

      }, (err) => {
        this.errorService.errorHandler(err);
        this.isAddingTicket = false;
      });
    }
  }

  addDepartment(name: string) {
    this.addedDepartment = {
      id: 0,
      name: name,
    };
    this.departmentService.add(this.addedDepartment).subscribe((item: any) => {
      this.informationService.add(item.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      this.listOfDepartments();
    });
  }

  changePartner() {
    this.project = new Project();
    this.project.id = 0;
    this.isLoading= true;
    this.projectService.getListofContinuingByPartnerId(this.partner.id).subscribe((res: any) => {
      this.isLoading = false
      this.projects = res.data;
    });
  }


  mentionSourceCallback(searchTerm: string, renderList: (items: any[], searchTerm: string) => void) {
    // Simulated data for demonstration purposes
    const mentionItems = this.users.map(user => {
        return {
            id: user.id,
            value: user.firstName  // Change the parameter name here
        };
    });

    if (searchTerm.length === 0) {
        // Render full list when search term is empty
        renderList(mentionItems, searchTerm);
    } else {
        // Filter mention items based on search term
        const matches = mentionItems.filter(item =>
            item.value.toLowerCase().includes(searchTerm.toLowerCase())
        );
        // Render filtered list
        renderList(matches, searchTerm);
    }
  }

  renderMentionItem(item) {
      return item.value;
  }

  editorCreated(quillEditor: any) {
    this.quillEditor = quillEditor;
    // Attach text-change event listener
    this.quillEditor.on('text-change', () => {
        this.detectMentionDeletion();
    });
  }


  detectMentionDeletion() {
    // Get the current content of the editor
    const currContent = this.quillEditor.root.innerHTML;
    // Check if there was previous content
    if (this.prevContent !== null) {
        // Extract mentions from previous content
        const prevMentions = this.extractMentions(this.prevContent);
        const currMentions = this.extractMentions(currContent);
        // Find deleted mentions
        const deletedMentions = prevMentions.filter(mention => !currMentions.includes(mention));
        // Log deleted mentions
        if (deletedMentions.length > 0) {
            deletedMentions.forEach((deletedMention: any) => {
                this.userList = this.userList.filter(x => x.id != Number.parseInt(deletedMention))
            })
            // Perform your operation when mention(s) are deleted
        }
    }
    // Update the previous content to the current content
    this.prevContent = currContent;
  }

// Function to extract mentions from the editor content
  extractMentions(content) {
      // Regular expression to match mentions
      const mentionRegex = /<span class="mention" data-index="(.*?)" data-denotation-char="@" data-id="(.*?)" data-value="(.*?)">(.*?)<\/span>/g;
      // Extract mention values
      const mentions = [];
      let match;
      while ((match = mentionRegex.exec(content)) !== null) {
          mentions.push(match[2]);
      }
      return mentions;
  } 

  configureFileUploader() {
    this.uploader = new FileUploader({
        url: URL,
        isHTML5: true,

    });
    this.uploader.onBeforeUploadItem = (item) => {
        item.withCredentials = false;
    }
  }
}
