import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { ActivityInquiryDate } from '../models/activity-inquiry-date';
import { ActivityInquiry } from '../models/activityinquiry';
import { ActivityInquiryList } from '../models/activityinquirylist';

@Injectable({
  providedIn: 'root'
})
export class ActivityInquiryService {

  private baseApiUrl=environment.apiUrl;

  constructor(public httpClient:HttpClient) { }

  getListByUserIdAndDate(id:number,year:ActivityInquiryDate):Observable<ActivityInquiryList[]>{
    return this.httpClient.post<ActivityInquiryList[]>(this.baseApiUrl+'ActivityInquiry/GetListByUserIdAndDate/'+id,year);
  }


  getListByDate(year:ActivityInquiryDate):Observable<ActivityInquiryList[]>{
    return this.httpClient.post<ActivityInquiryList[]>(this.baseApiUrl+'ActivityInquiry/GetListByDate/',year);
  }


  getLocationTypeList():Observable<any[]>{
    return this.httpClient.get<any[]>(this.baseApiUrl+'GetLocationTypeList');
  }

  getActivityTypeList():Observable<any[]>{
    return this.httpClient.get<any[]>(this.baseApiUrl+'GetActivityTypeList');
  }

  get(id:number) :Observable <ActivityInquiry>{
    return this.httpClient.get<ActivityInquiry>(this.baseApiUrl+"ActivityInquiry/GetById/"+id);
  }

  add(activityInquiry:ActivityInquiry):Observable<ActivityInquiry>{
    return this.httpClient.post<ActivityInquiry>(this.baseApiUrl+"ActivityInquiry/Add",activityInquiry)
  }

  update(activityInquiry:ActivityInquiry):Observable<ActivityInquiry>{
    return this.httpClient.post<ActivityInquiry>(this.baseApiUrl+"ActivityInquiry/Update",activityInquiry)
  }

  delete(activityInquiry:ActivityInquiry):Observable<ActivityInquiry>{
    return this.httpClient.post<ActivityInquiry>(this.baseApiUrl+"ActivityInquiry/Delete",activityInquiry)
  }


}
