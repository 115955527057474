import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Project } from '../models/project';

@Injectable({
  providedIn: 'root'
})
export class EvoProjectService {

  private baseApiUrl = environment.apiUrl;

  constructor(public httpClient: HttpClient) { }

  getList(): Observable<Project[]> {
    return this.httpClient.get<Project[]>(this.baseApiUrl + 'GetProjectList');
  }

  getListByPartnerId(id: number): Observable<Project[]> {
    return this.httpClient.get<Project[]>(this.baseApiUrl + 'GetProjectListByPartnerId/' + id);
  }

  getContinuingList(): Observable<Project[]> {
    return this.httpClient.get<Project[]>(this.baseApiUrl + 'GetContinuingProjectList');
  }

  getListofContinuingByPartnerId(id: number): Observable<Project[]> {
    return this.httpClient.get<Project[]>(this.baseApiUrl + 'GetContinuingProjectListByPartnerId/' + id);
  }
  getListofContinuingByPartnerIdWhitPermission(id: number, userid:number): Observable<Project[]> {
    return this.httpClient.get<Project[]>(this.baseApiUrl + 'GetContinuingProjectListByPartnerIdWithPermission/' + id+"?userId="+userid);
  }
  chechkProjectView(userId: number, ticketId: number): Observable<Project> {
    return this.httpClient.get<Project>(this.baseApiUrl + 'CheckProjectView/' + userId + '/' + ticketId);
  }


  get(id: number): Observable<Project> {
    return this.httpClient.get<Project>(this.baseApiUrl + 'GetProject/' + id);
  }

  add(project: Project): Observable<Project> {
    return this.httpClient.post<Project>(this.baseApiUrl + 'AddProject/', project);
  }

  update(project: Project): Observable<Project> {
    return this.httpClient.post<Project>(this.baseApiUrl + "UpdateProject/", project);
  }

  delete(project: Project): Observable<Project> {
    return this.httpClient.post<Project>(this.baseApiUrl + "DeleteProject/", project);
  }

  GetProjectExpenseList(userId: number) {
    return this.httpClient.get<any>(this.baseApiUrl + "GetProjectExpenseList?userId=" + userId)
  }

  deleteProjectExpensePermission(userId: number, projectId: number) {
    return this.httpClient.get<any>(this.baseApiUrl + "DeleteProjectExpense?userId=" + userId + "&projectId=" + projectId)
  }
  AddProjectExpensePermission(projectExpensePermission : any) {
    return this.httpClient.post<any>(this.baseApiUrl + "AddProjectExpensePermission", projectExpensePermission);
  }

}
