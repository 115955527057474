<div class="content-wrapper container-xxl p-0">
  <div class="content-body" >
    <section  id="ngx-datatable-kitchen-sink">
      <core-card-snippet>
        <h4 class="card-title">Bildirdiğim Taleplerin Listesi</h4>
        <div class="row">
          <div class="firstRow mt-1 mt-1">
            <div style="margin-left:9px; margin-bottom: 20px;">
              <div class="col-12">
                <select class="form-control form-select" [(ngModel)]="basicSelectedOption">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
          </div>
          <div class="search mt-1">
            <div class="col-12">
              <input type="text" class="form-control" name="filterText" placeholder="Arama Yapın.."
                [(ngModel)]="filterText" [ngModelOptions]="{standalone:true}">
            </div>
          </div>
          <div class="filter mt-1">
            <div class="col-12">
              <ng-select [multiple]="true" [items]="priorities" [(ngModel)]="selectedPriorities"
                placeholder="Öncelik Seçiniz" bindLabel="name" bindValue="id" (change)="tableByFiltersId()">
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label"> {{ item.name }}</span>
                  <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                </ng-template>
                <ng-template ng-header-tmp>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="filter mt-1">
            <div class="col-12">
              <ng-select [items]="statusFilter" [multiple]="true" bindValue="id" bindLabel="name"
                placeholder="Durum Seçiniz" (change)="tableByFiltersId()" groupBy="typeName"
                [(ngModel)]="selectedStatuses">
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label"> {{ item.name }}</span>
                  <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="filter mt-1" *ngIf="(!firstLevelRestriction && !secondLevelRestriction )" >
            <div class="col-12"
              *ngIf="userRole=='Admin' || userRole=='Consultant' || userRole=='ConsultantUser'else emptyTemplate">
              <ng-select [multiple]="true" [items]="partners" [(ngModel)]="selectedPartners" placeholder="Cari Seçiniz"
                bindLabel="name" bindValue="id" (change)="tableByFiltersId()">
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label"> {{ item.name }}</span>
                  <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                </ng-template>
                <ng-template ng-header-tmp>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <ng-template #emptyTemplate>
            <div class="col-2"></div>
          </ng-template>
        </div>
        <div class="row  mb-2">
          <div class="col-5 ml-1">
            <div class="col-12">
              <button class="btn btn-primary btn-sm mr-1" routerLink="/home/addticket" rippleEffect title="Yeni Talep">
                <i data-feather="plus"></i>
              </button>
              <button class="btn btn-info btn-sm" (click)="exportExcel()" rippleEffect title="Excele Aktar">
                <i data-feather="file-text"></i>
              </button>
            </div>
          </div>


          <div id="dateFilter" class="row col-6" style="display: flex;justify-content: end;">
            <form class="form-inline">
              <div class="form-group">
                <div class="input-group w-0">
                  <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                    [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2"
                    [dayTemplate]="rangeSelectionDP" outsideDays="hidden" [startDate]="fromDateExample!" />
                  <ng-template #rangeSelectionDP let-date let-focused="focused">
                    <span class="custom-day" type="datetime" [class.focused]="focused" [class.range]="isRange(date)"
                      [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                      (mouseleave)="hoveredDate = null">
                      {{ date.day }}
                    </span>
                  </ng-template>
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <input #dpFromDate class="form-control" type="date" placeholder="dd-MM-yyyy" name="dpFromDate"
                  [ngModel]="checkType(fromDateExample) ? formatter.format(fromDateExample) : fromDateExample" (ngModelChange)="fromDateExample = $event; tableByDates();" />
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary feather icon-calendar" (click)="datepicker.toggle()"
                      type="button" rippleEffect></button>
                  </div>
                </div>
              </div>
              <div class="form-group ml-sm-2">
                <div class="input-group">
                  <input #dpToDate class="form-control" type="date" placeholder="dd-MM-yyyy" name="dpToDate"
                  [ngModel]="checkType(toDateExample) ? formatter.format(toDateExample) : toDateExample" (ngModelChange)="toDateExample = $event; tableByDates();" />
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary feather icon-calendar" (click)="datepicker.toggle()"
                      type="button" rippleEffect></button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <table class="table table-hover table-bordered" id="excel-table" style="display: none;">
          <tbody>
            <tr>
              <td>Takip Numarası</td>
              <td>Öncelik</td>
              <td>Konu</td>
              <td>Bölüm</td>
              <td>Talep Tipi</td>
              <td>Partner Adı</td>
              <td>Oluşturulma Tarihi</td>
              <td>Durum</td>
            </tr>
            <tr *ngFor="let item of tickets">
              <td>{{item.trackingNumber}}</td>
              <td>
                <div *ngIf="item.priorityId==1">Yüksek</div>
                <div *ngIf="item.priorityId==2">Normal</div>
                <div *ngIf="item.priorityId==3">Düşük</div>
              </td>
              <td>{{item.subject}}</td>
              <td>{{item.departmentName}}</td>
              <td>{{item.typeName}}</td>
              <td>{{item.partnerName}}</td>
              <td [innerHTML]="item.createdDate | date:'yyyy-MM-dd HH:mm:ss'"></td>                                            <td>{{item.trackingNumber}}</td>
              <td>
                Yeni Oluşturuldu
              </td>
            </tr>
          </tbody>
        </table>
        <ngx-datatable [rows]="tickets |evoTicket:filterText:fromDate:toDate" [rowHeight]="58"
          class="bootstrap core-bootstrap" [limit]="10" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
          [limit]="basicSelectedOption">
          <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false"
            [resizeable]="false">
            <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
              let-selectFn="selectFn">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" [checked]="allRowsSelected"
                  (change)="selectFn(!allRowsSelected)" id="headerChkbxRef" />
                <label class="custom-control-label" for="headerChkbxRef"></label>
              </div>
            </ng-template>
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value"
              let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" [checked]="isSelected"
                  (change)="onCheckboxChangeFn($event)" id="rowChkbxRef{{ rowIndex }}" />
                <label class="custom-control-label" for="rowChkbxRef{{rowIndex}}"></label>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Takip No" prop="trackingNumber" [width]="150">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
              <div class="d-flex align-items-center">
                <div class="cell-line-height">
                  <a class="badge badge-pill badge-success font-small-3 font-weight-bold line-height-2 mb-25"
                    routerLink="/home/details/{{row.id}}" target="">#{{ name }}</a>

                  <!-- altına tatlı ve açık gri bir açıklama eklemek için kullanılabilr -->
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Öncelik" prop="priorityId" [width]="150">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
              <div class="d-flex align-items-center">
                <div class="badge badge-pill badge-light-danger mr-1" *ngIf="row.priorityId==1">Yüksek</div>
                <div class="badge badge-pill badge-light-primary mr-1" *ngIf="row.priorityId==2">Normal</div>
                <div class="badge badge-pill badge-light-secondary mr-1" *ngIf="row.priorityId==3">Düşük</div>
              </div>
              <span class="text-muted font-small-2"> {{ row.typeName }}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Konu" prop="subject" [width]="250">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
              {{row.subject.length > 30 ? row.subject.slice(0, 30) + '...' : row.subject}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Bölüm" prop="departmentName" [width]="180">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
              {{row.departmentName.length > 30 ? row.departmentName.slice(0, 30) + '...' : row.departmentName}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Partner" prop="partnerName" [width]="240">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
              <div class="text-muted font-small-2" *ngIf="row.partnerId==0; else partnerTemplate">
                Atanmış Şirket bulunmamakta
              </div>
              <ng-template #partnerTemplate>
                {{row.partnerName.length > 30 ? row.partnerName.slice(0, 30) + '...' : row.partnerName}}
                <br>
                <span *ngIf="row.partnerTicketNo!=null; else emptyPartnerTicketNoTemplate "
                  class="text-muted font-small-2"> {{ row.partnerTicketNo }}</span>
                <ng-template #emptyPartnerTicketNoTemplate></ng-template>
              </ng-template>
            </ng-template>
          </ngx-datatable-column>
          
          <ngx-datatable-column name="Oluşturma Tarihi" prop="createdDate" [width]="180">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
              {{row.createdDate|date:'dd-MM-yyyy HH:mm'}}
              <br>
              <span class="text-muted font-small-2"> {{row.createdByUserName}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="İşlemler" [width]="120" [sortable]="false">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
              <div class="d-flex align-items-center">
                <div ngbDropdown container="body">
                  <a ngbDropdownToggle href="javascript:void(0);" (click)="getTicketUserTypeList(row.id)"
                    class="hide-arrow" id="dropdownBrowserState" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                  </a>
                  <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownBrowserState">
                    <a routerLink="/home/details/{{row.id}}" ngbDropdownItem><i data-feather="edit-2"
                        class="mr-50"></i><span>Güncelle</span></a>
                    <button ngbDropdownItem (click)="deletedTicketId=row.id" data-bs-toggle="modal"
                      data-bs-target="#deleteticket">
                      <i data-feather="trash" class="mr-50"></i><span>Sil</span>
                    </button>
                    <button ngbDropdownItem data-bs-toggle="modal" data-bs-target="#addticketuser"
                      (click)="saveTicket(row.id)">
                      <!-- routerLink="/home/addticketuser/{{row.id}}" -->
                      <i data-feather="user" class="mr-50"></i><span>Kulllanıcı Ata</span></button>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </core-card-snippet>

      <div class="modal fade" id="addticketuser" data-bs-backdrop="static" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="staticBackdropLabel">Kullanıcı Ata</h1>
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                  class="mr-50"></i>Çıkış</button>
            </div>
            <div class="modal-body">
              <div class="card-body">
                <form class="form form-vertical" #AddTicketUserForm="ngForm">
                  <div class="col-12">
                    <div class="form-group mb-2">
                      <label for="prioritySelect">Kullancı Seçiniz</label>
                      <select class="form-control" required name="slUser" [(ngModel)]="user.id" #slUser="ngModel">
                        <option *ngIf="userRole=='Admin'||userRole=='ConsultantUser' || userRole=='Consultant'"
                          value="{{myId}}">{{userName}}
                        </option>
                        <option *ngFor="let item of consultants" value="{{item.id}}">{{item.firstName}}
                          {{item.lastName}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <button type="button" rippleEffect class="btn btn-primary m-1 modal-footer" data-bs-dismiss="modal"
                      (click)="addTicketUser()"><i data-feather="send" class="mr-50"></i>Kaydet</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="deleteticket" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="staticBackdropLabel">Talebi Sil</h1>
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                  class="mr-50"></i>Çıkış</button>
            </div>
            <div class="modal-body">
              Kaydı Silmek İstediğinize Emin Misiniz?
            </div>
            <div class="modal-footer">
              <div>
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                  (click)="delete(deletedTicketId)"><i data-feather="trash" class="mr-50"></i>Sil</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>