import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Department } from '../models/department';
import { AnnualTermList } from '../models/annualtermlist';
import { AnnualTerm } from '../models/annualterm';
import { ActivityInquiryDate } from '../models/activity-inquiry-date';

@Injectable({
  providedIn: 'root'
})
export class EvoAnnualTermService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getList():Observable<AnnualTermList[]>{
    return this.httpClient.get<AnnualTermList[]>(this.baseApiUrl+'GetAnnualTermList');
  }
  getListDto():Observable<AnnualTermList[]>{
    return this.httpClient.get<AnnualTermList[]>(this.baseApiUrl+'GetAnnualTermListDto');
  }
  get (id: number):Observable <AnnualTerm> {
    return this.httpClient.get<AnnualTerm>(this.baseApiUrl+'GetAnnualTerm/'+id);
  }
  add(annualTerm:AnnualTerm):Observable<AnnualTerm>{
    return this.httpClient.post<AnnualTerm>(this.baseApiUrl+'AddAnnualTerm/',annualTerm);
  }

  addCompleteAnnualTerm(year:ActivityInquiryDate):Observable<AnnualTerm>{
    return this.httpClient.post<AnnualTerm>(this.baseApiUrl+'AddCompleteAnnualTerm/',year);
  }
  update(annualTerm:AnnualTerm):Observable<AnnualTerm>{
    return this.httpClient.post<AnnualTerm>(this.baseApiUrl+"UpdateAnnualTerm/",annualTerm);
  }
  delete(id:number):Observable<AnnualTerm>{
    return this.httpClient.post<AnnualTerm>(this.baseApiUrl+"DeleteAnnualTerm/"+id,id);
  }
}
