export class UserBirthdayIncluded{
    id:number;
    userName:string;
    firstName:string;
    title:string;
    lastName:string;
    password:string;
    mailAddress:string;
    phoneNumber:string;
    genderId:number;
    roleId:number;
    typeId:number;
    partnerId:number;
    imageURL:string;
    vacationCount:number;
    birthdayDate:any;
    partnerName:string;
    
    createdBy:number;
    updatedBy:number;
    deletedBy:number;
}