import { Component, OnInit, ViewChild } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

import * as snippet from 'app/evo/components/layout/swiper.snippetcode';
import { EvoExpenseService } from 'app/evo/services/evo-expense.service';
import { Expense } from 'app/evo/models/expense';
import { ExpenseType } from 'app/evo/models/expensetype';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { EvoProjectService } from 'app/evo/services/evo-project.service';
import { Project } from 'app/evo/models/project';
import { InformationService } from 'app/evo/services/information.service';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { User } from 'app/evo/models/user';
import { FormControl } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Router } from '@angular/router';
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from 'app/evo/services/helper.service';
import { EvoPermissionUserService } from 'app/evo/services/evo-permission-user.service';
import { EvoPermissionService } from 'app/evo/services/evo-permission.service';
import { DatePipe } from '@angular/common';
import { Partner } from 'app/evo/models/partner';
import { EvoPartnerService } from 'app/evo/services/evo-customer.service';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { environment } from 'environments/environment';

const URL =  environment.apiUrl + 'UploadExpenseFile';


@Component({
  selector: 'app-evo-expense',
  templateUrl: './evo-expense.component.html',
  styleUrls: ['./evo-expense.component.scss'
  ]
})

export class EvoExpenseComponent implements OnInit {
  alertClose: boolean = false;
  fromDate: Date;
  toDate: Date;
  Month: any;
  total: number = 0;
  allExpensesTotal: number = 0;

  myId: number = 0;
  userRole: string = "";
  userName: string = "";
  filterText: string = "";
  
  

  expensePaymentStatuses = [];

  public hoveredDate: NgbDate | null = null;
  public fromDateExample: NgbDate = null;
  public toDateExample: NgbDate = null;

  basicSelectedOption: number = 10;

  deletedExpenseId: number = 0;
  user: User = new User();
  partner: Partner = new Partner();
  expense: Expense = new Expense();
  project: Project = new Project();
  expenseType: ExpenseType = new ExpenseType();

  expenseTypes = [
    { id: 1, name: "Yemek" },
    { id: 2, name: "Ulaşım" },
    { id: 3, name: "Konaklama" },
  ];

  users: User[] = [];
  allUsers: User[] = [];
  expenses: Expense[] = [];
  projects: Project[] = [];
  partners: Partner[] = [];
  allExpenses: Expense[] = [];

  selectedExpenseTypes: ExpenseType[] = [];
  selectedProjects: Project[] = [];
  selectedPartners: any[] = [];
  selectedUsers: User[] = [];
  
  selectedExpenseStatus = [];

  selectedExpenses : Expense[] = []; // çoklu işlemler yapabilmek için seçilenler masrafları tutan liste

  listingRestriction: boolean;
  partnerRestriction: boolean;

  startDateControl: FormControl;
  startDateEffortControl: FormControl;


  now: Date = new Date();
  day: number = this.now.getDate();
  month: number = this.now.getMonth() + 1;
  year: number = this.now.getFullYear();
  hour: number = this.now.getHours();
  minute: number = this.now.getMinutes();
  
  today: string = new Date(this.year, this.month - 1, this.day, this.hour, this.minute).toLocaleDateString() + ' ' + new Date(this.year, this.month - 1, this.day, this.hour, this.minute).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
@ViewChild("mytable") mytable: DatatableComponent;
public uploader: FileUploader = null;
documents:any[]=[];

  constructor(
    private expenseService: EvoExpenseService,
    private projectService: EvoProjectService,
    private userService: EvoUserService,
    private partnerService: EvoPartnerService,
    private permissionService: EvoPermissionService,
    private permissionUserService: EvoPermissionUserService,

    private informationService: InformationService,
    public formatter: NgbDateParserFormatter,
    private router: Router,
    private helperService: HelperService,
    private errorService: ErrorService,
    private decodeService: DecodeService,

  ) {
    const today = new Date();
    this.Month = today.toLocaleString('default', { month: 'long', localeMatcher: 'best fit', timeZone: 'UTC', formatMatcher: 'basic' });


    const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
      direction: 'horizontal',
      observer: true
    };

    this.startDateControl = new FormControl(new Date().toISOString().slice(0, 16));
    this.startDateEffortControl = new FormControl(new Date().toISOString().slice(0, 16));

  }
  public contentHeader: object;
  public centeredSlideIndex = 2;
  public centeredSlide2Index = 2;

  // snippet code variables
  public _snippetCodeSwiperCoverflow = snippet.snippetCodeSwiperCoverflow;

  public swiperCoverflow: SwiperConfigInterface = {
    effect: 'coverflow',
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true
    },
    pagination: {
      el: '.swiper-pagination'
    }
  };


  ngOnInit(): void { 
    this.myId = this.decodeService.getUserId();
    this.userRole = this.decodeService.getRole();
    this.userName = this.decodeService.getUserName();
    
    if (this.userRole == "Customer" || this.userRole == "CustomerUser") {
      this.informationService.warning("Bu Sayfayı Görmek için Yetkiniz Bulunmamaktadır.")
      this.router.navigate(['/home']);
    }
    this.expenseService.PaymentStatusGetList().subscribe((res: any) => {
      this.expensePaymentStatuses = res.data
    })
    if (this.userRole == "Admin" || this.userRole == "Consultant" || this.userRole == "ConsultantUser") {
      this.partnerService.getList().subscribe((res: any) => {
        this.partners = res.data;
      });
    }
    this.getPermissions();

    this.contentHeader = {
      headerTitle: 'Swiper',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Home',
            isLink: true,
            link: '/'
          },
          {
            name: 'Extensions',
            isLink: true,
            link: '/'
          },
          {
            name: 'Swiper',
            isLink: false
          }
        ]
      }
    };

    this.configureFileUploader();
  }

  downloadImage(name: string) {

    this.expenseService.downloadDocument(this.expense.id,name).subscribe((data:any) => {
      const blob = new Blob([data.body as BlobPart], { type: data.body?.type });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download =name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);


    },(error:any)=>{
    });
  }

  configureFileUploader(){
    this.uploader = new FileUploader({
      url: URL,
      isHTML5: true,
      
    });
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    }
  }

  selectedFileUrl: string;

  previewImage(item: any) {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.selectedFileUrl = event.target.result;
    };
    reader.readAsDataURL(item.file);
  }

  clearFileQueue(){
    this.uploader.queue = [];
  }
  
  @ViewChild("mycl") colon : any;
  //ngx-datatable içerisindeki satırlardan seçilenleri bulup listenin içerisine alıyor
  onCheckboxChange(event: any, row: any) {
    const seciliMasraf = this.allExpenses[row];
    if (!this.selectedExpenses.includes(seciliMasraf)) {
      this.selectedExpenses.push(seciliMasraf);
    }
    else {
      // Masraf zaten seçiliyse, seçili olanlar listesinden kaldırın
      this.selectedExpenses = this.selectedExpenses.filter(masraf => masraf !== seciliMasraf);
    }
  }

  // ngx-datatable içerisindeki tüm satırları bir liste içerisine alıyor veya çıkarıyor
  onSelect({ selected }) {
    this.selectedExpenses.splice(0, this.selectedExpenses.length);
    this.selectedExpenses.push(...selected);
  }
  selecedtPaymentStatusId: number = 0;
  updatedPaymentStatusId:number = 0;

  updateMultiple() {
    this.selectedExpenses.forEach(item => {
      item.paymentStatusId = this.updatedPaymentStatusId
    })
    this.expenseService.updateMultiple(this.selectedExpenses).subscribe((res: any) => {
      setTimeout(() => {
        window.location.reload()
      }, 150)
    })

  }

  // bu method html sayfasında display none bir buttona tıklama işlemi yapılarak bosstrap modal açıyor
  // kullanıcı modal açıldıktan sonra işlemini iptal etme durumuna karşın select kutusu resetleniyor. ve seçili id bilgisi farklı bir değişkende saklanıyor
  openModal() {
    if (this.userRole == 'Admin' && this.selectedExpenses.length > 0 && this.selecedtPaymentStatusId != 0) {
      document.getElementById("openmodal").click()
      this.updatedPaymentStatusId = this.selecedtPaymentStatusId
      document.getElementById("resetselectbox").click()
    }
  }

  getMyExpenses() {
    if (this.userRole == 'Admin' && this.selecedtPaymentStatusId != 0) { }
    this.expenseService.getListforThisMonthByUserId(this.myId).subscribe((items: any) => {
      this.expenses = items.data;
      this.calculateTotal();
    });
  }

  list() {
    if (this.userRole == 'Admin' || this.userRole == 'Consultant') {
      this.expenseService.getList().subscribe((items: any) => {
        console.log("a")
        this.allExpenses = items.data;
        this.calculateTotal();
        this.informationService.list(items.message);
      }, (err) => {
        this.errorService.errorHandler(err);
      });

      this.userService.getList().subscribe((items: any) => {
        this.users = items.data;
        const index = this.users.findIndex(user => user.id === this.myId);
        if (index !== -1) {
          this.users.splice(index, 1);
        }
      }, (err) => {
        this.errorService.errorHandler(err);
      });
    }

    else {
      this.expenseService.getListByUserId(this.myId).subscribe((items: any) => {
        this.allExpenses = items.data;
        this.calculateTotal();
        this.informationService.list(items.message);
      }, (err) => {
        this.errorService.errorHandler(err);
      });
    }
  }


  getDocuments(event: any) {
    this.documents.push(this.uploader.queue);
  }

  getPermissions() {
    this.permissionUserService.getPermissionUserListByPermissionId(11, this.myId).subscribe((items: any) => {
      this.listingRestriction = !items.data;
      
      if (this.listingRestriction) {
        this.list();
        this.getMyExpenses();

        // this.projectService.getList().subscribe((items: any) => {
        //   this.projects = items.data;
        // });
        this.userService.getListForFilter().subscribe((items: any) => {
          this.allUsers = items.data;
        });
      }
      else {
        this.informationService.warning("Bu Sayfayı Görmek için Yetkiniz Bulunmamaktadır.")
        this.router.navigate(['/home']);
      }
    });
    this.permissionUserService.getPermissionUserListByPermissionId(10, this.myId).subscribe((items: any) => {
      this.partnerRestriction = !items.data;
      if (this.partnerRestriction) {
        this.partnerService.getList().subscribe((items: any) => {
          this.partners = items.data;
        });
      }
    });
  }

  tableByFiltersId() {
    if (this.userRole == "Admin" || this.userRole == "Consultant") {
      this.expenseService.getList().subscribe((items: any) => {
        let filteredItems = items.data;

        if (this.selectedProjects.length > 0) {
          filteredItems = filteredItems.filter(item => this.selectedProjects.includes(item.projectId));
        }
        if (this.selectedUsers.length > 0) {
          filteredItems = filteredItems.filter(item => this.selectedUsers.includes(item.userId));
        }
        if (this.selectedExpenseTypes.length > 0) {
          filteredItems = filteredItems.filter(item => this.selectedExpenseTypes.includes(item.typeId));
        }
        if (this.selectedExpenseStatus.length > 0) {
          filteredItems = filteredItems.filter(item => this.selectedExpenseStatus.includes(item.paymentStatusId));
        }

        if (this.fromDate && this.toDate) {
          filteredItems = filteredItems.filter(item =>
            new Date(item.paymentDate) >= new Date(this.fromDate) &&
            new Date(item.paymentDate) <= new Date(this.toDate)
          );
        }
        this.allExpenses = filteredItems;
        this.calculateTotal();
      });
    }
    else {
      this.expenseService.getListByUserId(this.myId).subscribe((items: any) => {
        let filteredItems = items.data;

        if (this.selectedProjects.length > 0) {
          filteredItems = filteredItems.filter(item => this.selectedProjects.includes(item.projectId));
        }
        if (this.selectedExpenseTypes.length > 0) {
          filteredItems = filteredItems.filter(item => this.selectedExpenseTypes.includes(item.typeId));
        }

        this.allExpenses = filteredItems;
        this.calculateTotal();
      });
    }

  }

  checkType(value: any): value is NgbDate {
    return value instanceof NgbDate;
  }

  tableByDates() {
    var tempDate: any = {};
    var datePipe = new DatePipe("en-US");

    if (this.fromDateExample instanceof NgbDate) {
      tempDate = { day: this.fromDateExample.day, month: this.fromDateExample.month - 1, year: this.fromDateExample.year };
    }
    else {
      var fromDateTransformed = new Date(datePipe.transform(this.fromDateExample, 'yyyy-MM-dd'));
      tempDate = { day: fromDateTransformed.getDate(), month: fromDateTransformed.getMonth(), year: fromDateTransformed.getFullYear() }
    }

    this.fromDate = new Date(tempDate.year, tempDate.month, tempDate.day, 0, 0, 0);

    if (this.toDateExample == null)
      this.toDate = new Date(tempDate.year, tempDate.month, tempDate.day, 23, 59, 59);
    else
      var tempToDate: any = {};

    if (this.toDateExample instanceof NgbDate) {
      tempToDate = { day: this.toDateExample.day, month: this.toDateExample.month - 1, year: this.toDateExample.year };
    }
    else {
      var toDateTransformed = new Date(datePipe.transform(this.toDateExample, 'yyyy-MM-dd'));
      tempToDate = { day: toDateTransformed.getDate(), month: toDateTransformed.getMonth(), year: toDateTransformed.getFullYear() }
    }

    this.toDate = new Date(tempToDate.year, tempToDate.month, tempToDate.day, 23, 59, 59);


    if (!this.fromDate || !this.toDate) {
      this.list();
    } else {
      if (this.userRole == "Admin" || this.userRole == "Consultant") {
        this.expenseService.getList().subscribe((items: any) => {
          this.allExpenses = items.data.filter(item =>
            new Date(item.paymentDate) >= this.fromDate &&
            new Date(item.paymentDate) <= this.toDate
          );

        });
      } else {
        this.expenseService.getListByUserId(this.myId).subscribe((items: any) => {
          this.allExpenses = items.data.filter(item =>
            new Date(item.paymentDate) >= this.fromDate &&
            new Date(item.paymentDate) <= this.toDate
          );
        });
      }
    }
    this.tableByFiltersId();
  }


  calculateTotal() {
    this.total = 0;
    this.allExpensesTotal = 0;
    this.expenses.forEach(element => {
      this.total += element.payment;
    });
    this.allExpenses.forEach(element => {
      this.allExpensesTotal += element.payment;
    });
  }


  getMyExpense(id: number) {
    this.uploader.queue = [];
    
    this.expenseService.get(id).subscribe((items: any) => {
      this.projectService.getListofContinuingByPartnerId(items.data.partnerId).subscribe((res:any)=>{
        this.projects = res.data
        this.expense = items.data;
        this.partner.id = this.expense.partnerId
  
        let allFiles:Array<FileItem>=[];
        const uniqueFiles = new Set<string>();

        this.expense.documents.forEach((item:any)=>{
          let file = new File([item.content], item.fileName, {type: item.mimeType});

          const fileIdentifier = `${file.name}-${file.size}-${file.type}`;

          if (!uniqueFiles.has(fileIdentifier)) {
            uniqueFiles.add(fileIdentifier);
        
            const fileItem = new FileItem(this.uploader, file, {});
            this.uploader.queue.push(fileItem);
          }
        
        })
      })

    }, (err) => {
      this.errorService.errorHandler(err);
    });


  }

  add(payment: number, description: string, paymentDate: string) {
    let today;

    if (paymentDate == this.today) {
      today = new Date();
    } else {
      today = new Date(paymentDate);
    }
    if (this.userRole != 'Admin' && this.userRole != 'Consultant') {
      this.user.id = this.myId;
    }
    this.expense = {
      id: 0,
      typeId: this.expenseType.id,
      userId: this.user.id,
      projectId: this.project.id,
      description: description,
      payment: payment,
      paymentDate: today,
      userOnLeave:0,
      projectTitle: "",
      userName: "",
      userMail: "",
      typeName: "",
    }

    let formData = new FormData();
    formData.append("Id", this.expense.id.toString());
    formData.append("TypeId", this.expenseType.id.toString());
    formData.append("UserId", this.user.id.toString());
    formData.append("ProjectId", this.project.id.toString());
    formData.append("Description", description.toString());
    formData.append("Payment", payment.toString().replace(".",","));
    formData.append("PaymentDate",paymentDate.toString());
    formData.append("UserOnLeave", "0");

    formData.append("ProjectTitle","");
    formData.append("UserName", "");
    formData.append("UserMail", "");
    formData.append("TypeName", "");

    for (let i = 0; i < this.uploader.queue.length; i++) {
      formData.append('Documents', this.uploader.queue[i]._file)
    }

    this.expenseService.add(formData).subscribe((items: any) => {
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.ngOnInit();
      }, 500);
    });
  }

  update() {

    let formData = new FormData();
    formData.append("Id", this.expense.id.toString());
    formData.append("TypeId", this.expense.typeId.toString());
    formData.append("UserId", this.expense.userId.toString());
    formData.append("ProjectId", this.expense.projectId.toString());
    formData.append("Description", this.expense.description.toString());
    formData.append("Payment", this.expense.payment.toString().replace(".",","));
    formData.append("PaymentDate",this.expense.paymentDate.toString());
    formData.append("PaymentStatusId",this.expense.paymentStatusId.toString());
    formData.append("PaymentStatusName",this.expense.paymentStatusName.toString());
    formData.append("ProjectTitle",this.expense.projectTitle);
    formData.append("UserName", this.expense.userName);
    formData.append("CreatedBy", "1");
    formData.append("UpdatedBy", this.myId.toString());
    
    formData.append("UserMail", this.expense.userMail);
    formData.append("TypeName",this.expense.typeName);
    formData.append("CauseOfRejection", this.expense.causeOfRejection);


    for (let i = 0; i < this.uploader.queue.length; i++) {
      formData.append('Documents', this.uploader.queue[i]._file)
    }
  
    this.expenseService.update(formData).subscribe((items: any) => {
      
      this.informationService.update(items.message);
      
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.ngOnInit();
      }, 500);
    });
  }

  delete(id: number) {
    this.expenseService.delete(id).subscribe((items: any) => {
      this.informationService.delete(items.message);
      this.selectedExpenses = this.selectedExpenses.filter(x=>x.id!=id);
      var checkbox = document.getElementById("rowChkbxRef" + id) as HTMLInputElement;
      checkbox.checked = false;
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.list()
        this.tableByFiltersId()
      }, 500);
    });
  }

  exportExcel() {
    let element = document.getElementById("table-excel");
    let title = "Masrafların Listesi";
    this.helperService.exportExcel(element, title);
  }
  exportExcelMyList() {
    let element = document.getElementById("table-excel-my-list");
    let title = this.Month + " Ayı Masraflarım";
    this.helperService.exportExcel(element, title);
  }



  isHovered(date: NgbDate) {
    return (
      this.fromDateExample && !this.toDateExample && this.hoveredDate && date.after(this.fromDateExample) && date.before(this.hoveredDate)
    );
  }
  isInside(date: NgbDate) {
    return this.toDateExample && date.after(this.fromDateExample) && date.before(this.toDateExample);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDateExample) ||
      (this.toDateExample && date.equals(this.toDateExample)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDateExample && !this.toDateExample) {
      this.fromDateExample = date;
    } else if (this.fromDateExample && !this.toDateExample && date && date.after(this.fromDateExample)) {
      this.toDateExample = date;
    } else {
      this.toDateExample = null;
      this.fromDateExample = date;
    }
    this.tableByDates();
  }

  changePartner() {
    this.projects = [];
    this.selectedPartners.forEach((selectedPartner) => {
      this.projectService.getListofContinuingByPartnerId(this.partner.id).subscribe((res: any) => {
        this.projects = this.projects.concat(res.data);
      });
    });

    if (this.selectedPartners.length == 0) {
      this.projectService.getList().subscribe((items: any) => {
        this.projects = items.data;
      });
    }
  }

  changePartnertoAdd(){
    this.projectService.getListofContinuingByPartnerId(this.partner.id).subscribe((res: any) => {
      this.projects = res.data;
    });
  
  }

  changeProjectWithPartner(){
    this.project = new Project()
    if(this.partner.id > 0){
      this.projectService.getListofContinuingByPartnerId(this.partner.id).subscribe((res: any) => {
        this.projects = res.data;
      });
    }
  }
  changeProjectWithPartnerUpdate(){
    this.project = new Project()
    if(this.partner.id > 0){
      this.projectService.getListofContinuingByPartnerId(this.partner.id).subscribe((res: any) => {
        this.projects = res.data;
      });
    }
  }

  rowClass = (row) => {
    console.log(localStorage.getItem('backgroundColor'));

    return {
      'color-onLeave': row.userOnLeave == 1 && this.userRole == 'Admin'&&localStorage.getItem("backgroundColor")!='dark',
      'color-onLeaveDarkMode': row.userOnLeave == 1 && this.userRole == 'Admin'&&localStorage.getItem("backgroundColor")=='dark',
      'color-normal': (row.userOnLeave == 0),
    };
  }


  // ödendi ödenmedi bilgisinin classını bu fonksiyon belirliyor  
  paymentStateClass(row: any) {
    if (row.paymentStatusId == 1) {
      return "badge badge-pill badge-light-danger mr-1"
    }
    else if( row.paymentStatusId == 2 ){
      return "badge badge-pill badge-light-success mr-1"
    }
    else if( row.paymentStatusId == 3 ){
      return "badge badge-pill badge-light-primary mr-1"
    }
    else if( row.paymentStatusId == 4 ){
      return "badge badge-pill badge-light-dark mr-1"
    }
  }

}
function param(target: EvoExpenseComponent, propertyKey: 'selected'): void {
  throw new Error('Function not implemented.');
}

