
<p-confirmDialog appendTo="body" [baseZIndex]="10000" #confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
<p-contextMenu appendTo="body" #cm [baseZIndex]="10000" [model]="contextMenuItems"></p-contextMenu>

<div class="content-wrapper container-xxl p-0">
    <div class="content-body">

        <section id="ngx-datatable-kitchen-sink">
            <core-card-snippet>
                <h4 class="card-title">Yetkinliklerin Listesi</h4>
                <div class="row">
                    <div class="search mt-1 ml-1">
                        <p-treeSelect 
                        class="ml-1"
                        name="slcModule"
                        [options]="filterModules"
                        [(ngModel)]="selectedMainModules"
                        (ngModelChange)="tableByFiltersId()"
                        [showClear]="true"
                        (onClear)="tableByFiltersId()"
                        placeholder="Modül Seçiniz"
                        [panelStyle]="{'width': '500px'}"
                        [style]="{'max-width':'500px'}"
                        [metaKeySelection]="false"
                        selectionMode="multiple"
                        display="chip"
                        ></p-treeSelect>
                    </div>
                    <div class="search mt-1" *ngIf="userRole=='Admin'">
                        <div class="col-12">
                            <span>
                                <p-multiSelect 
                                [options]="users" 
                                [(ngModel)]="selectedUsers"
                                (ngModelChange)="tableByFiltersId();handleSelectionChange();"
                                placeholder="Kullanıcı Seçiniz"
                                optionLabel="firstName"
                                optionValue="id"
                                [maxSelectedLabels]="2" 
                                [selectedItemsLabel]="'...'" 
                                [display]="selectedUsers.length > 2 ? 'comma' : 'chip'"
                                [showClear]="false"></p-multiSelect>                
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-12 mb-1 ml-1">
                        <button class="btn btn-primary btn-sm ml-1 mr-1" data-bs-toggle="modal"
                            data-bs-target="#addcompetence" (click)="clear();action='Ekle'" rippleEffect title="Yeni Yetkinlik Ekle">
                            <i data-feather="plus"></i><span class="d-none d-sm-inline-block"></span>
                        </button>
                        <button class="btn btn-info btn-sm" (click)="exportExcel()" rippleEffect title="Excele Aktar">
                            <i data-feather="file-text" class=""></i>
                        </button>
                    </div>
                </div>
                <table class="table table-hover table-bordered" id="excel-table" style="display: none;">
                    <thead>
                        <tr>
                            <td>Modül Adı</td>
                            <td>Açıklama</td>
                            <td>Kullanıcı Adı</td>
                            <td>Seviye (1-5)</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of competences">
                            <td>{{item.moduleName}}</td>
                            <td>{{item.description}}</td>
                            <td>{{item.userName}}</td>
                            <td>{{item.competenceLevelId}}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="form-group mb-2" style="width:98%; margin: 0 auto;">
                    <p-tree 
                    [value]="filteredCompetenceMainTree" 
                    selectionMode="single" 
                    [draggableNodes]="false" 
                    [droppableNodes]="false"
                    >
                  
                    <ng-template let-node pTemplate="default">
                        <table style="width:100%; table-layout: fixed;">
                            <tr>
                                <!-- Label Column -->
                                <td style="width: 1fr; padding: 8px;">
                                    {{node.label}}
                                </td>
                        
                                <ng-container *ngIf="node.children.length == 0 && node.key != '0'">
                                    <!-- User Name Column -->
                                     
                                    <td style="width: 1fr; padding: 8px;">
                                        {{node.data.userName}}
                                    </td>
                                    <!-- Select Competence Level Column -->
                                    <td style="width: 1fr; padding: 8px;">
                                        <select class="form-control" name="slcLevel" [(ngModel)]="node.data.competenceLevelId" disabled>
                                            <option [value]="null">Bilgi Seviyesi Seçiniz</option>
                                            <option *ngFor="let item of competenceLevels" [value]="item.id">{{item.label}}</option>
                                        </select>
                                    </td>
                                    <!-- Description Input Column -->
                                    <td style="width: 1fr; padding: 8px;">
                                        <input
                                            placeholder="Açıklama giriniz."
                                            type="text"
                                            id="txtDescriptionMain_{{node.key}}"
                                            class="form-control"
                                            name="txtDescriptionMain_{{node.key}}"
                                            #txtDescription="ngModel"
                                            [(ngModel)]="node.data.description"
                                            disabled
                                        />
                                    </td>
                                    <!-- Edit Button Column -->
                                    <td style="width: 50px; padding: 8px;">
                                        <button (click)="get(node.data.id);action='Değiştir'" data-bs-toggle="modal"
                                                data-bs-target="#addcompetence" class="btn btn-warning btn-sm">
                                            <i class="pi pi-pencil"></i>
                                        </button>
                                    </td>
                                    <!-- Delete Button Column -->
                                    <td style="width: 50px; padding: 8px;">
                                        <button (click)="delete(node.data.id);" class="btn btn-danger btn-sm">
                                            <i class="pi pi-trash"></i>
                                        </button>
                                    </td>
                                </ng-container>
                            </tr>
                        </table>                   
                    </ng-template>
                  </p-tree>
                </div>

            </core-card-snippet>


            <div class="modal fade" id="addcompetence" tabindex="-1" aria-labelledby="staticBackdropLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Yetkinlik Bilgisi Ekle</h1>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                                    data-feather="x-circle" class="mr-50"></i>Çıkış</button>
                        </div>
                        <div class="modal-body">
                            <form class="" #AddCompetence="ngForm">
                                <div class="modal-body">
                                    <div class="form-group">

                                        <div class="row mb-2">
                                            <div class="col-6" *ngIf="userRole == 'Admin'">
                                                <select name="slcUser" class="form-control form-select" required
                                                    #slcUser="ngModel" [(ngModel)]="user.id">
                                                    <option [value]="undefined" disabled>Kullanıcı Seçiniz</option>
                                                    <option *ngFor="let item of users" [value]="item.id">
                                                        {{item.firstName}}
                                                    </option>
                                                </select>
                                                <small class="text-danger" *ngIf="slcUser.invalid && slcUser.touched">Lütfen
                                                    bir Kullanıcı seçiniz!</small>
                                            </div>
                                            <div class="col-6" *ngIf="action!='Değiştir'">
                                                <p-treeSelect 
                                                name="slcModule"
                                                [options]="filterModules"
                                                [(ngModel)]="selectedModules"
                                                (ngModelChange)="filterCompetenceModuleTree()"
                                                (onClear)="filterCompetenceModuleTree()"
                                                [showClear]="true"
                                                placeholder="Modül Seçiniz"
                                                [panelStyle]="{'width': '500px'}"
                                                [metaKeySelection]="false"
                                                selectionMode="multiple"
                                                display="chip"
                                                >
                                            </p-treeSelect>
                                            </div>
                                        

                                        </div>


                                        <p-tree 
                                        [value]="filteredCompetenceModuleTree" 
                                        selectionMode="single" 
                                        (onNodeDrop)="nodeDrop($event)"
                                        [draggableNodes]="userRole=='Admin'?true:false" 
                                        [droppableNodes]="userRole=='Admin'?true:false" 
                                        [(selection)]="selectedNode"
                                        (onNodeContextMenuSelect)="onNodeRightClick($event)"
                                        [contextMenu]="userRole=='Admin' ? cm : null"
                                        >
                                      
                                        <ng-template let-node pTemplate="default">
                                            <table style="width:100%; table-layout:fixed;">
                                                <tr>
                                                    <td style="width:30%; padding:8px;">
                                                        {{node.label}}
                                                    </td>
                                                    <ng-container *ngIf="node.children.length==0 && node.key!='0'">
                                                        <td style="width:25%;padding:8px;">
                                                            <select class="form-control" id="slcLevelModule_{{node.key}}" name="slcLevelModule_{{node.key}}" required name="slcLevel"
                                                            [(ngModel)]="node.data.competenceLevelId">
                                                            <option [value]="null"> Bilgi Seviyesi Seçiniz </option>
                                                            <option *ngFor="let competenceLevel of competenceLevels" value="{{competenceLevel.id}}">{{competenceLevel.label}}
                                                            </option>
                                                            </select>
                                                        </td>
                                                        <td style="width:30%;padding:8px;">
                                                            <input
                                                            placeholder="Açıklama giriniz."
                                                            type="text"
                                                            id="txtDescriptionModule_{{node.key}}"
                                                            class="form-control"
                                                            name="txtDescriptionModule_{{node.key}}"
                                                            #txtDescription="ngModel"
                                                            [(ngModel)]="node.data.description"
                                                            required
                                                            />
                                                        </td>
                                                        <td style="width:15%;padding:8px;">
                                                            <button (click)="saveCompetence(node)" [disabled]="node.data.description=='' || node.data.competenceLevelId==null" class="btn btn-outline-secondary btn-sm">
                                                                <i class="pi pi-plus"></i> {{action}}
                                                            </button>
                                                        </td>
                                                    </ng-container>                                       
                                                </tr>
                                            </table>   
                                        </ng-template>
                                      </p-tree>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="addCompetenceModal" tabindex="-1" aria-labelledby="staticBackdropLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Yetkinlik Modülü Ekle</h1>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                                    data-feather="x-circle" class="mr-50"></i>Çıkış</button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group">
                                <label for="name-vertical"> Yetkinlik Ad(ları) Giriniz.</label>
                                <textarea [(ngModel)]="competenceBatchNamesString" ngModel required id="name-vertical" style="height: 75px;"
                                    class="form-control" name="txtMainAddress" placeholder="Yetkinlik Adları"
                                    #txtMainAddress="ngModel"></textarea>

                                <small class="text-danger"
                                    *ngIf="txtMainAddress.invalid&& txtMainAddress.touched">Yetkinlik adları boş bırakılamaz.</small>
                            </div>                        
                        </div>
                        <div class="modal-footer">
                            <div>
                                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                                    (click)="addCompetenceBatch()" [disabled]="txtMainAddress.invalid"><i data-feather="plus"
                                        class="mr-50"></i>Yetkinlik Modülü Ekle</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="renameCompetenceModal" tabindex="-1" aria-labelledby="staticBackdropLabel"
                aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Modül Adı Değiştir</h1>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                                    data-feather="x-circle" class="mr-50"></i>Çıkış</button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group">
                                <label for="name-vertical"> Yeni Ad Giriniz.</label>
                                <input type="text" [(ngModel)]="competenceRenamed" ngModel required id="name-vertical"
                                    class="form-control" name="txtRename" placeholder="Yetkinlik Modülü Adı"
                                    #txtRename="ngModel">

                                <small class="text-danger"
                                    *ngIf="txtRename.invalid&& txtRename.touched">Yetkinlik adı boş bırakılamaz.</small>
                            </div>                        
                        </div>
                        <div class="modal-footer">
                            <div>
                                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                                    (click)="renameCompetenceModule()" [disabled]="txtRename.invalid"><i class="pi pi-pencil"
                                        ></i>Ad Değiştir</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="deletecompetence" tabindex="-1" aria-labelledby="staticBackdropLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Yetkinlik Sil</h1>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                                    data-feather="x-circle" class="mr-50"></i>Çıkış</button>
                        </div>
                        <div class="modal-body">
                            Kaydı Silmek İstediğinize Emin Misiniz?
                        </div>
                        <div class="modal-footer">
                            <div>
                                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                                    (click)="delete(deletedCompetenceId)"><i data-feather="trash"
                                        class="mr-50"></i>Sil</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>