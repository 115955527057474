import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EvoExpenseService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getList(){
    return this.httpClient.get(this.baseApiUrl+'GetExpenseList/' );
  }
  getListforThisMonth(){
    return this.httpClient.get(this.baseApiUrl+'GetExpenseListforThisMonth/' );
  }
  getListByUserId(userId:number){
    return this.httpClient.get(this.baseApiUrl+'GetExpenseListByUserId/'+userId );
  }
  getListforThisMonthByUserId(userId:number){
    return this.httpClient.get(this.baseApiUrl+'GetExpenseListforThisMonthByUserId/'+userId );
  }

  get (id: number) {
    return this.httpClient.get(this.baseApiUrl+'GetExpense/'+id);
  }
  add(expense:any){
    return this.httpClient.post(this.baseApiUrl+'AddExpense/',expense);
  }
  update(expense:any){
    return this.httpClient.post(this.baseApiUrl+"UpdateExpense/",expense);
  }
  updateMultiple(expenses:any[]){
    return this.httpClient.post(this.baseApiUrl+"UpdateMultipleExpense/",expenses);
  }
  delete(id:number){
    return this.httpClient.post(this.baseApiUrl+"DeleteExpense/"+id,id);
  }

  PaymentStatusGetList(){
    return this.httpClient.get(this.baseApiUrl + "ExpensePaymentStatusGetList");
  }

  downloadDocument(id:number,document:string){
    return this.httpClient.get(this.baseApiUrl+'DownloadExpenseFile/'+id + "/" + document,{observe:'response',responseType: 'blob'});
  }

}
