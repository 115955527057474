export class ActivityInquiry{
    id:number=0;
    activityTypeId:number;
    locationTypeId:number;
    userId:number;
    moduleId:number;
    partnerId:number;
    projectId:number;
    ticketId:number|null=null;
    description:string;
    billTypeId:number=4;
    activityDate:any;
    activityHours:number;
    createdBy:number|null=null;
    updatedBy:number|null = null;
    deletedBy:number|null = null;

}