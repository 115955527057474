<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <section id="ngx-datatable-kitchen-sink">
      <core-card-snippet>
        <h4 class="card-title">Tüm Taleplerin Listesi</h4>
        <div class="row mb-2 ">
          <div class="firstRow mt-1 mt-1">
            <div style="margin-left:9px;">
              <div class="col-12">
                <select class="form-control form-select" [(ngModel)]="basicSelectedOption">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
          </div>
          <div class="search mt-1">
            <div class="col-12">
              <input type="text" class="form-control" name="filterText" placeholder="Arama Yapın.."
                [(ngModel)]="filterText" [ngModelOptions]="{standalone:true}">
            </div>
          </div>
          <div class="filter mt-1">
            <div class="col-12">
              <ng-select [multiple]="true" [items]="selectMultiUsers | async" [(ngModel)]="selectedUsers"
                placeholder="Kullanıcı Seçiniz" bindLabel="firstName" bindValue="id" (change)="tableByFiltersId()">
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label">{{ item.firstName }} {{item.lastName}}</span>
                  <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                </ng-template>
              </ng-select>
            </div>
          </div>

          <div class="filter mt-1">
            <div class="col-12">
              <ng-select [multiple]="true" [items]="priorities" [(ngModel)]="selectedPriorities"
                placeholder="Öncelik Seçiniz" bindLabel="name" bindValue="id" (change)="tableByFiltersId()">
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label"> {{ item.name }}</span>
                  <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                </ng-template>

              </ng-select>
            </div>
          </div>
          <div class="filter mt-1">
            <div class="col-12">
              <ng-select [items]="statusFilter" [multiple]="true" bindValue="id" bindLabel="name"
                placeholder="Durum Seçiniz" (change)="tableByFiltersId()" groupBy="typeName"
                [(ngModel)]="selectedStatuses">
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label"> {{ item.name }}</span>
                  <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                </ng-template>
              </ng-select>
            </div>
            <!--               
              <ng-select [items]="statusFilter" id="statusSelect" [multiple]="true" (change)="tableByFiltersId()"
                [closeOnSelect]="false" groupBy="typeName" [(ngModel)]="selectedStatuses" [searchable]="false" bindValue="id" bindLabel="name"
                placeholder="Durum Seçiniz">
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label"> {{ item.name }}</span>
                  <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                </ng-template>
              </ng-select> 
            -->
          </div>

          <div class="filter mt-1" *ngIf="(!firstLevelRestriction && !secondLevelRestriction )">
            <div class="col-12" *ngIf="userRole=='Admin' || userRole=='Consultant' || userRole=='ConsultantUser' ">
              <ng-select id="partnerSelect" [multiple]="true" [items]="partners" [(ngModel)]="selectedPartners"
                placeholder="Cari Seçiniz" bindLabel="name" bindValue="id" (change)="tableByFiltersId()">
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label"> {{ item.name }}</span>
                  <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                </ng-template>
                <ng-template ng-header-tmp>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="row  mb-2">
          <div class="col-5 ml-1">
            <div class="col-12">
              <button class="btn btn-primary btn-sm mr-1" routerLink="/home/addticket" rippleEffect title="Yeni Talep">
                <i data-feather="plus"></i>
              </button>
              <button class="btn btn-info btn-sm" (click)="exportExcel()" rippleEffect title="Excele Aktar">
                <i data-feather="file-text"></i>
              </button>
            </div>
          </div>


          <!-- <div *ngIf=" userRole=='Customer' || userRole=='CustomerUser'" class="col-2"></div> -->

          <div id="dateFilter" class="row col-6" style="display: flex;justify-content: end;">
            <form class="form-inline">
              <div class="form-group">
                <div class="input-group w-0">
                  <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                    [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2"
                    [dayTemplate]="rangeSelectionDP" outsideDays="hidden" [startDate]="fromDateExample!" />
                  <ng-template #rangeSelectionDP let-date let-focused="focused">
                    <span class="custom-day" type="datetime" [class.focused]="focused" [class.range]="isRange(date)"
                      [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                      (mouseleave)="hoveredDate = null">
                      {{ date.day }}
                    </span>
                  </ng-template>
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <input #dpFromDate class="form-control" type="date" placeholder="dd-MM-yyyy" name="dpFromDate"
                    [ngModel]="checkType(fromDateExample) ? formatter.format(fromDateExample) : fromDateExample"
                    (ngModelChange)="fromDateExample = $event; tableByDates();" />
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary feather icon-calendar" (click)="datepicker.toggle()"
                      type="button" rippleEffect></button>
                  </div>
                </div>
              </div>
              <div class="form-group ml-sm-2">
                <div class="input-group">
                  <input #dpToDate class="form-control" type="date" placeholder="dd-MM-yyyy" name="dpToDate"
                    [ngModel]="checkType(toDateExample) ? formatter.format(toDateExample) : toDateExample"
                    (ngModelChange)="toDateExample = $event; tableByDates();" />
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary feather icon-calendar" (click)="datepicker.toggle()"
                      type="button" rippleEffect></button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>


        <table class="table table-hover table-bordered" id="table-excel" style="display: none;">
          <tbody>
            <tr>
              <td>Takip Numarası</td>
              <td>Talep Sorumlusu</td>
              <td>Öncelik</td>
              <td>Konu</td>
              <td>Bölüm</td>
              <td>Talep Tipi</td>
              <td>Faturalanma Tipi</td>
              <td>Partner Adı</td>
              <td>Durum</td>
              <td>Oluşturulma Tarihi</td>
            </tr>
            <tr *ngFor="let item of newTicketUsers">
              <td>{{item[0].trackingNumber}}</td>
              <td>{{item[0].userFirstName}} {{item[0].userLastName}} {{item[0].typeName}}</td>
              <td>
                <div *ngIf="item[0].priorityId==1">Yüksek</div>
                <div *ngIf="item[0].priorityId==2">Normal</div>
                <div *ngIf="item[0].priorityId==3">Düşük</div>
              </td>
              <td>{{item[0].subject}}</td>
              <td>{{item[0].departmentName}}</td>
              <td>{{item[0].ticketTypeName}}</td>
              <td>{{item[0].billTypeName}}</td>
              <td>{{item[0].partnerName}}</td>
              <td>
                <div *ngIf="item[0].statusId==2">Danışmandan Cevap Bekleniyor</div>
                <div *ngIf="item[0].statusId==3">Sonlandırıldı</div>
                <div *ngIf="item[0].statusId==4">Tamamlandı</div>
                <div *ngIf="item[0].statusId==5">İptal</div>

                <div *ngIf="item[0].statusId==8">Müşteriden Cevap Bekleniyor</div>
                <div *ngIf="item[0].statusId==9">Danışmandan Cevap Bekleniyor</div>
                <div *ngIf="item[0].statusId==10">Test Sürecinde</div>
              </td>
              <td [innerHTML]="item[0].createdDate | date:'yyyy-MM-dd HH:mm:ss'"></td>
              <td>{{item.trackingNumber}}</td>
            </tr>
          </tbody>
        </table>
        <ngx-datatable #table [rows]="newTicketUsers |evoTicketuser: filterText:fromDate:toDate" [rowHeight]="60"
          class="bootstrap core-bootstrap" [sorts]="sortOptions" [limit]="10" [headerHeight]="40" [footerHeight]="50"
          [scrollbarH]="true" [limit]="basicSelectedOption" (sort)="onSort($event)">
          <ngx-datatable-column [width]="50" [sortable]="false">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
              <div class="d-flex align-items-center">
                <div ngbDropdown container="body">
                  <a ngbDropdownToggle href="javascript:void(0);" (click)="getTicketUserTypeList(row[0].ticketId)"
                    class="hide-arrow" id="dropdownBrowserState" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <div class="avatar avatar-status ng-star-inserted bg-light-primary"
                      *ngIf="row[0].billTypeName=='Faturalanmadı'" ng-reflect-ng-class="[object Object]">
                      <span placement="top" container="body" [ngbTooltip]="tipContent" class="avatar-content"
                        ng-reflect-placement="top" ng-reflect-container="body"
                        ng-reflect-ngb-tooltip="[object Object]"><i
                          class="feather avatar-icon font-medium-1 icon-file-minus"
                          ng-reflect-ng-class="[object Object]"></i></span>
                    </div>
                    <div class="avatar avatar-status ng-star-inserted bg-light-info"
                      *ngIf="row[0].billTypeName=='Faturalandı'" ng-reflect-ng-class="[object Object]">
                      <span placement="top" container="body" [ngbTooltip]="tipContent" class="avatar-content"
                        ng-reflect-placement="top" ng-reflect-container="body"
                        ng-reflect-ngb-tooltip="[object Object]"><i
                          class="feather avatar-icon font-medium-1 icon-check-square"
                          ng-reflect-ng-class="[object Object]"></i></span>
                    </div>

                    <div class="avatar avatar-status ng-star-inserted bg-light-danger"
                      *ngIf="row[0].billTypeName=='Feragat Edildi'" ng-reflect-ng-class="[object Object]">
                      <span placement="top" container="body" [ngbTooltip]="tipContent" class="avatar-content"
                        ng-reflect-placement="top" ng-reflect-container="body"
                        ng-reflect-ngb-tooltip="[object Object]"><i
                          class="feather avatar-icon font-medium-1 icon-minus-circle"
                          ng-reflect-ng-class="[object Object]"></i></span>
                    </div>
                    <ng-template class="font-weight-bold mb-2" #tipContent>Durum <br> <span class="font-weight-bold">
                      </span> {{row[0].billTypeName}}<span><br />
                        <span class="font-weight-bold">Talep Tipi:</span>
                        {{row[0].ticketTypeName}}</span>
                      <br>
                      <span class="font-weight-bold">Molülü:</span>
                      {{row[0].moduleName}}
                    </ng-template>
                  </a>
                  <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownBrowserState">
                    <a routerLink="/home/details/{{row[0].ticketId}}" ngbDropdownItem href="javascript:void(0);">
                      <i data-feather="info" class="mr-50"></i><span>Detay</span>
                    </a>
                    <button ngbDropdownItem data-bs-toggle="modal"
                      *ngIf="userRole=='Admin' || userRole=='Consultant' || userRole=='ConsultantUser' "
                      data-bs-target="#addticketactivity" (click)="saveTicketUser(row[0])">
                      <i data-feather="clock" class="mr-50"></i><span>Aktivite Ekle</span>
                    </button>
                    <button ngbDropdownItem data-bs-toggle="modal"
                      *ngIf="userRole=='Admin' || userRole=='Consultant' || userRole=='ConsultantUser' "
                      data-bs-target="#updateticketuser" (click)="saveTicketUser(row[0])"><i data-feather="edit-2"
                        class="mr-50"></i><span>Danışman
                        Değiştir</span></button>
                    <button ngbDropdownItem data-bs-toggle="modal" data-bs-target="#updateticketstatus"
                      (click)="saveTicket(row[0].ticketId)"><i data-feather="edit-2" class="mr-50"></i><span>Durum
                        Değiştir</span></button>
                    <button *ngIf="userRole=='Admin'|| userRole=='Consultant' || userRole =='ConsultantUser'"
                      ngbDropdownItem data-bs-toggle="modal" data-bs-target="#updateticketbilltype"
                      (click)="saveTicket(row[0].ticketId)"><i data-feather="edit-2"
                        class="mr-50"></i><span>Faturalandırma Durumu
                        Değiştir</span></button>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Takip No" prop="trackingNumber" [width]="150">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
              <div class="d-flex align-items-center">
                <div class="cell-line-height">
                  <a class="badge badge-pill badge-success font-small-3 font-weight-bold line-height-2 mb-25"
                    routerLink="/home/details/{{row[0].ticketId}}" target="">#{{row[0].trackingNumber}}</a>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Talep Sorumlusu" prop="userFirstName" [width]="180">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
              <div class="d-flex mb-0">
                <div *ngFor="let item of row; let i = index" class="avatar ml-0 pull-up ng-star-inserted"
                  [ngClass]="{'bg-light-warning': item.typeName=='Sorumlu', 'bg-light-primary': item.typeName=='Takipçi'}"
                  placement="top">
                  <div *ngIf="i<4">

                    <span *ngIf="item.imageURL==null; else imageUserTemplate"
                      class="avatar-content avatar-status ng-star-inserted text-center" ng-reflect-placement="top"
                      placement="top" container="body" ng-reflect-container="body"
                      ng-reflect-ngb-tooltip="[object Object]"
                      ngbTooltip="{{item.userFirstName}} {{item.userLastName}}">
                      <div>
                        {{item.userFirstName.charAt(0)}}{{item.userLastName.charAt(0)}}
                      </div>
                    </span>
                    <ng-template #imageUserTemplate>
                      <span class="avatar-content avatar-status ng-star-inserted text-center" ng-reflect-placement="top"
                        placement="top" container="body" ng-reflect-container="body"
                        ng-reflect-ngb-tooltip="[object Object]"
                        ngbTooltip="{{item.userFirstName}} {{item.userLastName}}">
                        <div *ngIf="item.typeName!='Sorumlu'; else followersTemplate">
                          <div class="avatar" placement="top" style="border: 2px solid rgb(76, 76, 200);
                          border-radius: 50%;
                          padding: 1px;">
                            <img src="../../../../assets/images/img/{{item.imageURL}}" style="border-radius:50px ;"
                              width="25" height="25" />
                          </div>
                        </div>
                        <ng-template #followersTemplate>
                          <div class="avatar" placement="top" style="border: 2px solid orange;
                          border-radius: 50%;
                          padding: 1px;">
                            <img src="../../../../assets/images/img/{{item.imageURL}}" style="border-radius:50px ;"
                              width="25" height="25" />
                          </div>
                        </ng-template>
                      </span>
                    </ng-template>
                  </div>
                  <ng-container *ngIf="i===4">
                    <div class="avatar pull-up ng-star-inserted bg-light-secondary" placement="top">
                      <span class="avatar-content avatar-status ng-star-inserted text-center" ng-reflect-placement="top"
                        placement="top" container="body" ng-reflect-container="body"
                        ng-reflect-ngb-tooltip="[object Object]" ngbTooltip="+{{row.length - 4}} kişi">
                        <div>
                          +{{row.length - 4}}
                        </div>
                      </span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Öncelik" prop="priorityId" [minWidth]="80" [width]="100">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
              <div class="d-flex align-items-center">
                <div class="badge badge-pill badge-light-danger mr-1" *ngIf="row[0].priorityId==1">Yüksek</div>
                <div class="badge badge-pill badge-light-primary mr-1" *ngIf="row[0].priorityId==2">Normal</div>
                <div class="badge badge-pill badge-light-secondary mr-1" *ngIf="row[0].priorityId==3">Düşük</div>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Konu" prop="subject"  [width]="250">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
              <!-- <div *ngIf="row[0].subject.length< 30; else sliceSubjectTemplate">
                {{row[0].subject}}
              </div>
              <ng-template #sliceSubjectTemplate>
                <div ng-reflect-placement="top" placement="top" container="body" ng-reflect-container="body"
                  ng-reflect-ngb-tooltip="[object Object]"
                  ngbTooltip="{{row[0].subject.length > 70 ? row[0].subject.slice(0, 70) + '...' : row[0].subject}}">
                  {{row[0].subject.length > 30 ? row[0].subject.slice(0, 25) + '...' : row[0].subject}}
                </div>
              </ng-template> -->
              <div> {{row[0].subject}} </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Bölüm" prop="departmentName" [width]="150">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
              {{row[0].departmentName.length > 25 ? row[0].departmentName.slice(0, 25) + '...' :
              row[0].departmentName}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Partner" prop="partnerName" [width]="150">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
              {{row[0].partnerName}}
              <br>
              <span *ngIf="row[0].partnerTicketNo!=null" class="text-muted font-small-2"> {{
                row[0].partnerTicketNo}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Oluşturma Tarihi" prop="createdDate" [width]="150">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
              {{row[0].createdDate|date:'dd-MM-yyyy HH:mm'}}
              <br>
              <span class="text-muted font-small-2"> {{row[0].createdByUserName}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Durum" prop="statusId" [width]="200">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
              <div class="align-items-center">
                <div class="badge badge-pill badge-light-dark mr-1" *ngIf="row[0].statusId==1">Yeni Oluşturuldu</div>
                <div class="badge badge-pill badge-light-primary mr-1" *ngIf="row[0].statusId==2">Danışmandan Cevap
                  Bekleniyor</div>
                <div class="badge badge-pill badge-light-secondary mr-1" *ngIf="row[0].statusId==3">Sonlandırıldı
                </div>
                <div class="badge badge-pill badge-light-success mr-1" *ngIf="row[0].statusId==4">Tamamlandı</div>
                <div class="badge badge-pill badge-light-danger mr-1" *ngIf="row[0].statusId==5">İptal</div>
                <div class="badge badge-pill badge-light-warning mr-1" *ngIf="row[0].statusId==8">Müşteriden Cevap
                  Bekleniyor</div>
                <div class="badge badge-pill badge-light-primary mr-1" *ngIf="row[0].statusId==9">Danışmandan Cevap
                  Bekleniyor</div>
                <div class="badge badge-pill badge-light-info mr-1" *ngIf="row[0].statusId==10">Test Sürecinde
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>

        </ngx-datatable>
        <div class="modal fade" id="addticketactivity" data-bs-backdrop="static" tabindex="-1"
          aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Yeni Aktivite Ekle</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                    class="mr-50"></i>Çıkış</button>
              </div>
              <div class="modal-body">
                <div class="card-body">
                  <form class="form form-vertical" #TicketActivityForm="ngForm">
                    <div class="col-12">
                      <div class="form-group mb-2">
                        <label>Başlangıç Tarihi Seçiniz</label>
                        <input type="datetime-local" class="form-control" name="txtStartDate"
                          [formControl]="startDateControl" required>
                      </div>
                      <div class="form-group mb-2">
                        <label for="tracking-number-vertical">Süre Giriniz</label>
                        <input type="number" class="form-control" name="txtTime" placeholder="Süre" #txtTime ngModel />
                        <small class="text-danger" *ngIf="txtTime.invalid&& txtTime.touched">Bir Süre girmeniz
                          gerekiyor!</small>
                      </div>
                      <div class="form-group mb-2">
                        <label for="moduleSelect">Modül Seçiniz</label>
                        <select class="form-control" required name="slcModule" [(ngModel)]="module.id"
                          #slcModule="ngModel">
                          <option *ngFor="let item of modules" value="{{item.id}}">{{item.name}}</option>
                        </select>
                      </div>
                      <div class="form-group mb-2">
                        <label for="prioritySelect">Lokasyon Tipi Seçiniz</label>
                        <select class="form-control" required name="slcLocationType" [(ngModel)]="locationType.id"
                          #slcLocationType="ngModel">
                          <option *ngFor="let item of locationTypes" value="{{item.id}}">{{item.name}}</option>
                        </select>
                      </div>
                      <quill-editor name="quillMessage" #quillDescription="ngModel" ngModel
                        placeholder="Bir mesaj giriniz..">
                        <div quill-editor-toolbar ngModel required>
                          <span class="ql-formats">
                            <button class="ql-bold" [title]="'Bold'"></button>
                            <button class="ql-italic" [title]="'italic'"></button>
                            <button class="ql-underline" [title]="'underline'"></button>
                          </span>
                          <span class="ql-formats">
                            <select class="ql-align" [title]="'Alignment'">
                              <option selected></option>
                              <option value="center"></option>
                              <option value="right"></option>
                              <option value="justify"></option>
                            </select>
                          </span>
                          <span class="ql-formats">
                            <button class="ql-list" value="ordered" type="button"></button>
                          </span>
                          <span class="ql-formats">
                            <button class="ql-link" value="ordered" type="button"></button>
                          </span>
                          <span class="ql-formats">
                            <button class="ql-formula" value="ordered" type="button"></button>
                            <button class="ql-code-block" value="ordered" type="button"></button>
                          </span>
                          <span class="ql-formats">
                            <button class="ql-clean" value="ordered" type="button"></button>
                          </span>
                        </div>

                      </quill-editor>

                    </div>
                    <div>
                      <button type="button" rippleEffect class="btn btn-primary m-1 modal-footer"
                        data-bs-dismiss="modal"
                        (click)="addActivity(startDateControl.value,quillDescription.value,txtTime.value)"><i
                          data-feather="send" class="mr-50"></i>Kaydet</button>
                    </div>
                  </form>

                </div>
              </div>

            </div>

          </div>
        </div>

        <div class="modal fade" id="updateticketuser" data-bs-backdrop="static" tabindex="-1"
          aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Danışman Değiştir</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                    class="mr-50"></i>Çıkış</button>
              </div>
              <div class="modal-body">
                <div class="card-body">
                  <form class="form form-vertical" #UpdateTicketUserForm="ngForm">
                    <div class="col-12">
                      <div class="form-group mb-2">
                        <label for="prioritySelect">Kullancı Seçiniz</label>
                        <select class="form-control" ngModel required name="slUser"
                          [(ngModel)]="selectedTicketUser.userId" #slUser="ngModel">
                          <option *ngIf="userRole=='Admin'||userRole=='ConsultantUser' || userRole=='Consultant'"
                            value="{{myId}}">{{userName}}
                          </option>
                          <option *ngFor="let item of consultants" value="{{item.id}}">{{item.firstName}}
                            {{item.lastName}}
                          </option>
                        </select>
                      </div>
                      <div class="form-group mb-2">
                        <label for="sclTicketUserType">Takip Rolünü Seçiniz</label>
                        <select class="form-control" ngModel required name="sclTicketUserType"
                          [(ngModel)]="selectedTicketUser.typeId" #sclTicketUserType="ngModel">
                          <option *ngFor="let item of ticketUserTypes" value="{{item.id}}">{{item.name}}</option>
                        </select>
                      </div>
                    </div>
                    <div>
                      <button type="button" rippleEffect class="btn btn-primary m-1 modal-footer"
                        data-bs-dismiss="modal" (click)="updateTicketUser()"><i data-feather="send"
                          class="mr-50"></i>Kaydet</button>
                    </div>
                  </form>

                </div>
              </div>

            </div>

          </div>
        </div>

        <div class="modal fade" id="updateticketstatus" data-bs-backdrop="static" tabindex="-1"
          aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Durum Değiştir</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                    class="mr-50"></i>Çıkış</button>
              </div>
              <div class="modal-body">
                <div class="card-body">
                  <form class="form form-vertical" #UpdateTicketUserForm="ngForm">
                    <div class="col-12">
                      <div class="form-group mb-2">
                        <label for="prioritySelect">Durum Seçiniz</label>
                        <select class="form-control" ngModel required name="slcUpdateStatus"
                          [(ngModel)]="selectedTicket.statusId" #slcUpdateStatus="ngModel">
                          <option *ngFor="let item of statusForChange" value="{{item.id}}">{{item.name}}
                          </option>
                          <option value="10" *ngIf="userRole!='Customer' && userRole!='CustomerUser' "> Test Sürecinde
                          </option>
                        </select>
                      </div>
                    </div>
                    <div>
                      <button type="button" rippleEffect class="btn btn-primary m-1 modal-footer"
                        data-bs-dismiss="modal" (click)="updateTicketStatus()"><i data-feather="send"
                          class="mr-50"></i>Kaydet</button>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="updateticketbilltype" data-bs-backdrop="static" tabindex="-1"
          aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Faturalandırma Durumu Değiştir</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                    class="mr-50"></i>Çıkış</button>
              </div>
              <div class="modal-body">
                <div class="card-body">
                  <form class="form form-vertical" #UpdateTicketUserForm="ngForm">
                    <div class="col-12">
                      <div class="form-group mb-2">
                        <label for="prioritySelect">Faturalandırma Durumu Seçiniz</label>
                        <select class="form-control" ngModel required name="slcUpdateBillType"
                          [(ngModel)]="selectedTicket.billTypeId" #slcUpdateBillType="ngModel">
                          <option *ngFor="let item of billTypes" value="{{item.id}}">{{item.name}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div>
                      <button type="button" rippleEffect class="btn btn-primary m-1 modal-footer"
                        data-bs-dismiss="modal" (click)="updateTicketStatus()"><i data-feather="send"
                          class="mr-50"></i>Kaydet</button>
                    </div>
                  </form>

                </div>
              </div>

            </div>

          </div>
        </div>

      </core-card-snippet>
      <div class="modal fade" id="deleteticketuser" tabindex="-1" aria-labelledby="staticBackdropLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="staticBackdropLabel">Kullanıcısı Atanmış Talebi Sil</h1>
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                  class="mr-50"></i>Çıkış</button>
            </div>
            <div class="modal-body">
              Kaydı Silmek İstediğinize Emin Misiniz?
            </div>
            <div class="modal-footer">
              <div>
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                  (click)="delete(deletedTicketUserId)"><i data-feather="trash" class="mr-50"></i>Sil</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>