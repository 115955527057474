import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EvoTicketMessageService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getList(id:number):Observable<any>{
    let api=this.baseApiUrl+'GetTicketMessageList/'+id;
    return this.httpClient.get(api);
  }
  getLastestMessageList(pageNumber:number):Observable<any>{
    return this.httpClient.get(this.baseApiUrl+'GetLastestTicketMessageList/'+pageNumber);
  }
  getLastestListWithPermissionDto(pageNumber:number,userId:number,firstRestriction:boolean,secondRestriction:boolean):Observable<any>{
    return this.httpClient.get(this.baseApiUrl+'GetLastestListWithPermissionDto/'+pageNumber+'/'+userId+'/'+firstRestriction+'/'+secondRestriction);
  }

  add(formData:any){
    let api=this.baseApiUrl+'AddTicketMessage/'
    return this.httpClient.post(api,formData);
  }

  sendMessage(ticketId:number,userId:number){
    return this.httpClient.get(this.baseApiUrl+'SendMessage/'+ticketId+'/'+userId);
  }

  delete(id:number) : Observable<any>{
    return this.httpClient.get<any>(this.baseApiUrl+'DeleteTicketMessage/'+id);
  }

  downloadDocument(id:number,document:string){
    return this.httpClient.get(this.baseApiUrl+'DownloadTicketMessageFile/'+id + "/" + document,{observe:'response',responseType: 'blob'});
  }
}
