export class Register{
    firstName:string;
    lastName:string;
    title:string;
    userName:string;
    phoneNumber:string;
    mailAddress:string;
    genderId:number;
    roleId:number;
    typeId:number;
    partnerId:number;
    password:string;
    vacationCount:number;
    gitlab?:string;
    discord?:string;
    birthdayDate?:Date;
    createdBy: number;
}