import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterTree'
})
export class FilterTreePipe implements PipeTransform {
  transform(items: any[], searchTexts: string[]): any[] {
    if (!items || !searchTexts || searchTexts.length === 0) {
      return items;
    }

    // Ensure all search texts are in lowercase and trimmed for consistent comparison
    const normalizedSearchTexts = searchTexts.map(text => text.toLocaleLowerCase('tr-TR').trim());

    return items
      .map(item => {
        const isMatch = this.matchesAnySearchText(item, normalizedSearchTexts);

        // Recursively filter children if the node doesn't match, or if it's a leaf node
        const filteredChildren = !isMatch && item.children ? this.transform(item.children, normalizedSearchTexts) : item.children;

        // If node is a match and it's not a leaf node, include all its children
        if (isMatch) {
          return {
            ...item,
            children: item.children // Keep all children if this node matches and it's not a leaf node
          };
        } 

        // If children are filtered and some match, return the node with the matching children
        if (filteredChildren && filteredChildren.length > 0) {
          return {
            ...item,
            children: filteredChildren
          };
        }

        // If neither the node nor its children match, return null
        return null;
      })
      .filter(item => item !== null);  // Filter out non-matching nodes
  }

  // Check if any search text matches the node's values
  matchesAnySearchText(item: any, searchTexts: string[]): boolean {
    return searchTexts.some(searchText => 
      Object.values(item).some(val => 
        typeof val === 'string' && val.toLocaleLowerCase('tr-TR').includes(searchText)
      )
    );
  }
}