export class Expense{
    id: number;
    typeId:number;
    typeName:string;
    userId:number;
    userName:string;
    userMail:string;
    projectId:number;
    projectTitle:string;
    description:string;
    createdBy?:number;
    payment:number;
    partnerId?:number;
    paymentDate:Date;
    paymentStatusName?:string;
    paymentStatusId?:number;
    documents?:any[];
    userOnLeave:number;
    causeOfRejection?:string;
}