import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {

  constructor(private router: Router,private toastr:ToastrService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = 'Bilinmeyen bir hata oluştu.';
        if (error.error instanceof ErrorEvent) {
          // Client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // Server-side error
          switch (error.status) {
            case 400:
              errorMessage = error.error;
              break;
            case 401:
                errorMessage = 'Yasak: Kimlik belirlemede hata oluştu.';
                localStorage.clear();
                this.router.navigate(['pages/authentication/login-v2']);

                break;
            case 403:
              errorMessage = 'Yasak: Bu işlemi yapmaya izniniz yok.';
              break;
            case 404:
              errorMessage = `Bulunamadı: ${error.message}`;
              break;
            case 500:
              errorMessage = 'Sunucu Hatası: Lütfen teknik destek ekibine başvurun.';
              break;
            case 550:
              errorMessage = "Mesajınız gönderilemedi. Lütfen boyutunu kontrol edip tekrar deneyiniz.";
              break;
            default:
              errorMessage = `Error Code: ${error.status} Message: ${error.error}`;
              break;
          }
        }


        this.toastr.error(errorMessage);

        return throwError(() => new Error(errorMessage));
      })
    );
  }
}