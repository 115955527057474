import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AuthService } from '../service/auth.service';
import { DecodeService } from '../service/decode.service';
import { catchError, concatMap, filter, switchMap, take } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable()
export class UserActiveInterceptor implements HttpInterceptor {
    private isRefreshing = false;

    constructor(private loginService: AuthService, private decodeService: DecodeService) {}
  
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
      if (this.isRefreshing) {
        return next.handle(request);
      }
  
      this.isRefreshing = true;
      const userId = this.decodeService.getUserId();
      
      return this.loginService.activeUser(userId).pipe(
        catchError(err => {
          return of(null); // Hata durumunda observable döndürür
        }),
        switchMap((res: any) => {
          this.isRefreshing = false;
          return next.handle(request);
        })
      );
    }
}
