import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Server } from '../models/server';
import { ServerStatus } from '../models/server-status';

@Injectable({
  providedIn: 'root'
})
export class EvoServerControlService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getList():Observable<Server[]>{
    return this.httpClient.get<Server[]>(this.baseApiUrl+'GetServerList');
  }

  get (id: number):Observable <Server> {
    return this.httpClient.get<Server>(this.baseApiUrl+'GetServer/'+id);
  }

  add(Service:Server):Observable<Server>{
    return this.httpClient.post<Server>(this.baseApiUrl+'AddServer/',Service);
  }

  update(Service:Server):Observable<Server>{
    return this.httpClient.post<Server>(this.baseApiUrl+"UpdateServer/",Service);
  }

  delete(id:number):Observable<Server>{
    return this.httpClient.post<Server>(this.baseApiUrl+"DeleteServer/"+ id,id);
  }

  getServerStatusList():Observable<ServerStatus[]>{
    return this.httpClient.get<ServerStatus[]>(this.baseApiUrl+'GetServerStatusList');

  }

  checkServerStatusNow():Observable<any>{
    return this.httpClient.get<any>(this.baseApiUrl+'CheckServerStatusNow');

  }
}
