import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { ActivityInquiryDate } from 'app/evo/models/activity-inquiry-date';
import { ActivityInquiry } from 'app/evo/models/activityinquiry';
import { ActivityInquiryList } from 'app/evo/models/activityinquirylist';
import { Competence } from 'app/evo/models/competence';
import { Contract } from 'app/evo/models/contract';
import { Module } from 'app/evo/models/module';
import { Partner } from 'app/evo/models/partner';
import { Project } from 'app/evo/models/project';
import { Ticket } from 'app/evo/models/ticket';
import { User } from 'app/evo/models/user';
import { ActivityInquiryService } from 'app/evo/services/activityinquiry.service';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoCompetenceService } from 'app/evo/services/evo-competence.service';
import { EvoContractService } from 'app/evo/services/evo-contract.service';
import { EvoPartnerService } from 'app/evo/services/evo-customer.service';
import { EvoModuleService } from 'app/evo/services/evo-module.service';
import { EvoProjectService } from 'app/evo/services/evo-project.service';
import { EvoTicketService } from 'app/evo/services/evo-ticket.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';
import { TabView } from 'primeng/tabview';

@Component({
  selector: 'app-evo-activity-inquiry-list',
  templateUrl: './evo-activity-inquiry-list.component.html',
  styleUrls: ['./evo-activity-inquiry-list.component.scss'
  ]
})
export class EvoActivityInquiryListComponent implements OnInit,AfterViewInit,OnDestroy {

    
    selectedYear: Date = new Date();
    selectedDate: Date;
    selectedMonth: number;
    dates: Date[] = [];
    activeIndex: number = 0;

    modules:Module[] = []
    partners:Partner[] = [];
    consultants:any[] = [];
    projects: Project[]=[];
    tickets : Ticket[]=[];
    billTypes:any[]=[];
    locationTypes:any[]=[];
    activityTypes:any[]=[];
    consultantFilters:any[]=[];
    selectedConsultantFilter:any[]=[];

    consultantTypeFilters: any[]=[
        {label:'İç Kaynak',value:1},{label:"Dış Kaynak",value:2}
    ]


    allRowCounts:any[]=[{id:10,label:"10"},{id:25,label:"25"},{id:50,label:"50"}]
    selectedConsultantTypeFilter:any[]=[{label:'İç Kaynak',value:1}];

    filteredInquiryList:ActivityInquiryList[]=[];
    activityInquiry : ActivityInquiry = new ActivityInquiry();
    activityInquiryList : ActivityInquiryList[] = [];
    consultantFilteredInquiryList: ActivityInquiryList[] = [];
    myId:number=0;
    userRole:string="";
    currentPage = 1;
    pageSize = 25;
    currentPageSummary = 1;
    summaryListSize= 0;
    mainListSize = 0;
    summaryRows:any[]=[];
    users : User[]=[];
    maxSelectedLabels : number= 2;
    pageReloading:number=0;
    consultantActivityFilters : any[]=[
        {label:'Aktif',value:"Aktif"},{label:"Pasif",value:"Pasif"}]

    selectedConsultantActivityFilter:any[]=[{label:'Aktif',value:"Aktif"}];

    months: { name: string, index: number }[] = [
      { name: 'Ocak', index: 0 },
      { name: 'Şubat', index: 1 },
      { name: 'Mart', index: 2 },
      { name: 'Nisan', index: 3 },
      { name: 'Mayıs', index: 4 },
      { name: 'Haziran', index: 5 },
      { name: 'Temmuz', index: 6 },
      { name: 'Ağustos', index: 7 },
      { name: 'Eylül', index: 8 },
      { name: 'Ekim', index: 9 },
      { name: 'Kasım', index: 10 },
      { name: 'Aralık', index: 11 }
    ];

    @ViewChild('tabView', { static: false }) tabView: TabView;


    loading:boolean=true;

    private themeLink: HTMLLinkElement;
    private primengLink: HTMLLinkElement;
    private primeiconsLink: HTMLLinkElement;

    
    constructor(private moduleService:EvoModuleService,
                private informationService: InformationService,
                private errorService:ErrorService,
                private partnerService:EvoPartnerService,
                private userService:EvoUserService,
                private projectService:EvoProjectService,
                private ticketService:EvoTicketService,
                private decodeService:DecodeService,
                private activityInquiryService:ActivityInquiryService
                ){
                    this.getUserInformation();

                }


    ngOnDestroy(): void {
        this.unloadPrimeNGStyles();
    }
    
    ngOnInit(): void {
        this.loadPrimeNGStyles();
        this.list();
        this.requiredList();
        
    }

    ngAfterViewInit(): void 
    {
    }

    loadPrimeNGStyles() {
        const head = document.getElementsByTagName('head')[0];
    
        // PrimeNG theme
        this.themeLink = document.createElement('link');
        this.themeLink.rel = 'stylesheet';
        this.themeLink.href = 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-light-blue/theme.css';
        head.appendChild(this.themeLink);

        // PrimeNG core styles
        this.primengLink = document.createElement('link');
        this.primengLink.rel = 'stylesheet';
        this.primengLink.href = 'https://cdn.jsdelivr.net/npm/primeng/resources/primeng.min.css';
        head.appendChild(this.primengLink);

        // PrimeIcons
        this.primeiconsLink = document.createElement('link');
        this.primeiconsLink.rel = 'stylesheet';
        this.primeiconsLink.href = 'https://cdn.jsdelivr.net/npm/primeicons/primeicons.css';
        head.appendChild(this.primeiconsLink);
      }
    
      unloadPrimeNGStyles() {
        if (this.themeLink) {
          this.themeLink.remove();
        }
        if (this.primengLink) {
          this.primengLink.remove();
        }
        if (this.primeiconsLink) {
          this.primeiconsLink.remove();
        }
      }

      handleSelectionChange(event?: any) {
        setTimeout(() => { // Ensure the DOM is updated
          const selectedItems = document.querySelectorAll('.p-multiselect-token .p-multiselect-token-label');
          selectedItems.forEach((item: HTMLElement) => {
            const text = item.textContent;
            if (text.length > 10) {
              const trimmedText = text.substring(0, 10) + '...';
              item.textContent = trimmedText;
            }
          });
        }, 0);
      }

    requiredList() {

        this.activityInquiryService.getLocationTypeList().subscribe((res:any)=>{
            this.locationTypes = res.data
        })

        this.userService.getActiveUserList().subscribe((items:any)=>{
            this.users = items.data;
            this.consultants = items.data.map(user => ({ label: user.firstName + " " + user.lastName, value: user.id,type: user.typeId }));
            this.filterUserType();
        })

        this.moduleService.getListDto().subscribe((item: any) => {
            this.modules = item.data;

          },(err) => {
        });

        this.partnerService.getList().subscribe((items: any) => {
            this.partners = items.data;
        }, (err) => {
        });

        this.ticketService.getBillTypeList().subscribe((items: any) => {
            this.billTypes = items.data;
        }, (err) => {
        });
        this.activityInquiryService.getActivityTypeList().subscribe((res:any)=>{
            this.activityTypes = res.data
        })

    }

    list(){
        
        let formData = new FormData();
        var today = new Date();
        today.setHours(today.getHours() - (today.getTimezoneOffset() / 60));

        var activityDateObject : ActivityInquiryDate = new ActivityInquiryDate();
        activityDateObject.activityDate = today;

        if(this.userRole!='Admin'){
            this.activityInquiryService.getListByUserIdAndDate(this.myId,activityDateObject).subscribe((res:any)=>{
                this.activityInquiryList = res.data;
                const today = new Date();
                this.selectedYear = today;
                this.selectedMonth = today.getMonth();
                this.initializeDates(this.selectedYear, this.selectedMonth);
                this.selectedDate = today;
                this.setActiveTab();

            })
        }
        else{
            this.activityInquiryService.getListByDate(activityDateObject).subscribe((res:any)=>{
                this.activityInquiryList = res.data;
                const today = new Date();
                this.selectedYear = today;
                this.selectedMonth = today.getMonth();
                this.initializeDates(this.selectedYear, this.selectedMonth);
                this.selectedDate = today;
                this.setActiveTab();

            })
        }
    }

    getYearlyData(){

        this.loading=true;

        this.selectedDate = new Date(this.selectedYear.getFullYear(), 0, 1); // January is month 0, day 1
        this.selectedDate.setHours(this.selectedDate.getHours() - (this.selectedDate.getTimezoneOffset() / 60));

        var activityDateObject : ActivityInquiryDate = new ActivityInquiryDate();
        activityDateObject.activityDate = this.selectedDate;


        if(this.userRole!='Admin'){
            this.activityInquiryService.getListByUserIdAndDate(this.myId,activityDateObject).subscribe((res:any)=>{
                this.activityInquiryList = res.data;
                
                this.selectedMonth = 0;
                this.initializeDates(this.selectedYear, this.selectedMonth);
                this.setActiveTab();
                this.scrollToActiveTab()

            })
        }
        else{
            this.activityInquiryService.getListByDate(activityDateObject).subscribe((res:any)=>{
                this.activityInquiryList = res.data;
                this.selectedMonth = 0;
                this.initializeDates(this.selectedYear, this.selectedMonth);
                this.setActiveTab();
                this.scrollToActiveTab()

            })
        }
    }
    getUserInformation() {
        this.userRole = this.decodeService.getRole();
        this.myId = this.decodeService.getUserId();
      }

    changePartner() {
        this.projectService.getListofContinuingByPartnerId(this.activityInquiry.partnerId).subscribe((res: any) => {
          this.projects = res.data;
        });
    }

    changeProject() {
        this.ticketService.getListByProjectId(this.activityInquiry.projectId).subscribe((res: any) => {
            this.tickets = res.data;
        });
    }

    initializeDates(year: Date, month: number) {
        this.dates = [];
        const daysInMonth = new Date(year.getFullYear(), month + 1, 0).getDate();
        for (let i = 1; i <= daysInMonth; i++) {
          this.dates.push(new Date(year.getFullYear(), month, i));
        }
    }

    selectMonth(monthIndex: number) {

        this.loading=true;

        this.activeIndex = 0;
        this.selectedMonth = monthIndex;
        this.selectedDate = new Date(this.selectedYear.getFullYear(), this.selectedMonth, 1); // January is month 0, day 1
        this.selectedDate.setHours(this.selectedDate.getHours() - (this.selectedDate.getTimezoneOffset() / 60));

        var activityDateObject : ActivityInquiryDate = new ActivityInquiryDate();
        activityDateObject.activityDate = this.selectedDate;
        
        if(this.userRole!='Admin'){
            this.activityInquiryService.getListByUserIdAndDate(this.myId,activityDateObject).subscribe((res:any)=>{
                this.activityInquiryList = res.data;
                this.initializeDates(this.selectedYear, monthIndex);
                this.selectDate();
                this.scrollToActiveTab()

            })
        }
        else{
            this.activityInquiryService.getListByDate(activityDateObject).subscribe((res:any)=>{
                this.activityInquiryList = res.data;
                this.initializeDates(this.selectedYear, monthIndex);
                this.selectDate()
                this.scrollToActiveTab()

            })
        }



    }

    selectDate() {

        this.loading=true;
        this.selectedDate =this.dates[this.activeIndex]
        this.setActiveTab();

    }

    scrollToActiveTab() {
        var addition = 6
        if(this.activeIndex>24){
            addition = 30- this.activeIndex
        }
        this.tabView.updateScrollBar(this.activeIndex+addition);
      }
    
    setActiveTab() {
        const strippedSelectedDate = this.stripTimeFromDate(this.selectedDate);
        this.filteredInquiryList = this.activityInquiryList.filter(x => {
            const activityDate = new Date(x.activityDate); // Ensure activityDate is a Date object
            const strippedActivityDate = this.stripTimeFromDate(activityDate);
            return strippedActivityDate.getTime() === strippedSelectedDate.getTime();
        });
        
        this.consultantFilteredInquiryList = [];
        this.summaryRows = [];
        this.summaryListSize = 0;
        this.mainListSize = 0;

        
        this.consultantFilteredInquiryList.push(undefined); 

        setTimeout(()=>{
            this.consultantFilteredInquiryList = this.filteredInquiryList;
            this.mainListSize = this.consultantFilteredInquiryList.length;
            this.currentPage = 1;
            this.currentPageSummary = 1;

            this.calculateSummary(this.consultantFilteredInquiryList);

            this.filterData("");

            this.loading=false;
        
        },1)
        
        this.activeIndex = this.dates.findIndex(date => date.toDateString() === this.selectedDate.toDateString());
       
        setTimeout(()=>{
            if(this.pageReloading== 0){
                this.scrollToActiveTab()
                this.pageReloading = 1;
            }
        },1)

    }

    populateUserFilters(){
        if(this.selectedConsultantActivityFilter.length==2 || this.selectedConsultantActivityFilter.length==0){
            this.userService.getAllUserList().subscribe((res:any)=>{

                this.consultants = res.data.map(user => ({ label: user.firstName + " " + user.lastName, value: user.id,type: user.typeId }));
                this.filterUserType();

            })
        }
        else if(this.selectedConsultantActivityFilter.length==1 ){
            if(this.selectedConsultantActivityFilter[0].value=="Aktif"){
                this.userService.getActiveUserList().subscribe((res:any)=>{
                    this.consultants = res.data.map(user => ({ label: user.firstName + " " + user.lastName, value: user.id,type: user.typeId }));
                    this.filterUserType();

                })
            }
            else{
                this.userService.getPassiveUserList().subscribe((res:any)=>{
                    this.consultants = res.data.map(user => ({ label: user.firstName + " " + user.lastName, value: user.id,type: user.typeId }));
                    this.filterUserType();

                })
            }
        }

    }

    filterUserType(){
        if(this.selectedConsultantTypeFilter.length==2 || this.selectedConsultantTypeFilter.length==0){
            this.consultantFilters = this.consultants;

        }
        else if(this.selectedConsultantTypeFilter.length==1 ){
            if(this.selectedConsultantTypeFilter[0].value==1){
                this.consultantFilters = this.consultants.filter(x=>x.type == 1);


            }
            else{
                this.consultantFilters = this.consultants.filter(x=>x.type == 2);

            }
        }

    }
    
    pageChange(page:number){
        this.currentPage = page;
    }
    pageChangeSummary(page:number){
        this.currentPageSummary = page;
    }
    calculateSummary(rows: any[]) {

        this.summaryRows = this.determineTotalActivity(rows,"single")
        this.summaryListSize = this.summaryRows.length
        this.currentPageSummary = 1;

    }

    determineTotalActivity(rows:any[],mode:string){
        const summaryMap = rows.reduce((acc, row) => {
            if (acc[row.userId]) {
                acc[row.userId].totalHours += row.activityHours;
            } else {
                acc[row.userId] = {
                userName: row.userName,
                totalHours: row.activityHours
                };
            }
            return acc;
        }, {} as { [key: string]: { userName: string, totalHours: number } });
        
        
        var summaryRows = Object.keys(summaryMap).map(userId => ({
            activityTypeName: 'Toplam',
            userId: userId,
            userName: summaryMap[userId].userName,
            totalHours: summaryMap[userId].totalHours
        }));

        if(this.userRole == 'Admin'){

            var noActivityRows = [];
            if(this.selectedConsultantFilter.length == 0){
                noActivityRows = this.consultantFilters;
            }
            else{
                noActivityRows = this.selectedConsultantFilter;
            }

            if(mode=="all"){
                noActivityRows=this.consultants;
            }

            const userIdsInActivity = rows.map(activity => activity.userId);
            // Filter the users whose id is not in the userIdsInActivity array
            const filteredUsers = noActivityRows.filter(user => !userIdsInActivity.includes(user.value));
            filteredUsers.forEach((user:any)=>{
                summaryRows = summaryRows.concat({
                        activityTypeName: 'Toplam',
                        userId: user.value,
                        userName: user.label,
                        totalHours: 0 
                })
            })
        }

        return summaryRows;

    }

    shouldShowTick(index: number): boolean {
        const summaryRows = this.getSummaryRowsForDate(this.dates[index]);
        return summaryRows.length > 0 && summaryRows.every(row => row.totalHours >= 8);
    }
      
    shouldShowExclamation(index: number): boolean {
        const summaryRows = this.getSummaryRowsForDate(this.dates[index]);
        return summaryRows.length === 0 || summaryRows.some(row => row.totalHours < 8);
    }
      
    getSummaryRowsForDate(date: Date): any[] {
        const strippedSelectedDate = this.stripTimeFromDate(date);
        var filteredInquiryList = this.activityInquiryList.filter(x => {
            const activityDate = new Date(x.activityDate); // Ensure activityDate is a Date object
            const strippedActivityDate = this.stripTimeFromDate(activityDate);
            return strippedActivityDate.getTime() === strippedSelectedDate.getTime();
        });
        
        var summaryRows = this.determineTotalActivity(filteredInquiryList,"all")
        return summaryRows;
    }

    filterData(filterType: string) {
        if (filterType === 'activity') {
          this.selectedConsultantFilter = [];
        }
        if(filterType==='reset'){
            this.selectedConsultantFilter = [];
            this.selectedConsultantActivityFilter = [];

        }
      
        let filteredList = this.filteredInquiryList;
      
        if (this.selectedConsultantActivityFilter && this.selectedConsultantActivityFilter.length) {
          filteredList = filteredList.filter(x =>
            this.selectedConsultantActivityFilter.some(consultant => consultant.label === x.isActiveUser)
          );
        }

        
        if (this.selectedConsultantTypeFilter && this.selectedConsultantTypeFilter.length) {
            filteredList = filteredList.filter(x =>
              this.selectedConsultantTypeFilter.some(consultant => consultant.value === x.userTypeId)
            );
        }
      
        if (this.selectedConsultantFilter && this.selectedConsultantFilter.length) {
          filteredList = filteredList.filter(x =>
            this.selectedConsultantFilter.some(consultant => consultant.label === x.userName)
          );
        }

      
        this.consultantFilteredInquiryList = filteredList;
        this.mainListSize = this.consultantFilteredInquiryList.length;
        this.currentPage = 1;

        this.calculateSummary(this.consultantFilteredInquiryList);
    }

    stripTimeFromDate(date: Date): Date {
        const strippedDate = new Date(date);
        strippedDate.setHours(0, 0, 0, 0);
        return strippedDate;
    }

    formatDateForInput(date: Date): string {
        // Format date as YYYY-MM-DDTHH:mm
        const pad = (n: number) => n < 10 ? '0' + n : n;
        return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
      }

    reset(){
        this.activityInquiry = new ActivityInquiry();
        this.activityInquiry.activityDate = this.formatDateForInput(this.selectedDate);

    }

    get(id:number){
        this.activityInquiryService.get(id).subscribe((res:any)=>{
            this.activityInquiry = res.data;
            this.activityInquiry.activityDate = this.formatDateForInput(new Date(this.activityInquiry.activityDate))
            this.changePartner();
            this.changeProject();
        })
    }

    saveInquiry(){
        if(this.userRole!='Admin'){
            this.activityInquiry.userId = this.myId;
        }

        if(this.activityInquiry.id == 0){
            this.addInquiry();
        }
        else{
            this.updateInquiry();
        }

    }

    addInquiry(){
        this.activityInquiry.createdBy = this.myId;

        this.activityInquiryService.add(this.activityInquiry).subscribe((res:any)=>{
            this.informationService.add("Aktivite başarıyla eklendi.");

            setTimeout(()=>{
                window.location.reload()
            },1500)
        },(err:any)=>{
            this.errorService.errorHandler(err)
        })
    }

    updateInquiry(){
        this.activityInquiry.updatedBy = this.myId;

        this.activityInquiryService.update(this.activityInquiry).subscribe((res:any)=>{
            this.informationService.update("Aktivite başarıyla güncellendi.");

            setTimeout(()=>{
                window.location.reload()
            },1500)

        },(err:any)=>{
            this.errorService.errorHandler(err)
        }) 
    }

    delete(id:number){

        var activityInquiry = new ActivityInquiry();
        activityInquiry.id = id;
        activityInquiry.deletedBy = this.myId;
        activityInquiry.description = "Silme işlemi";
        
        this.activityInquiryService.delete(activityInquiry).subscribe((res:any)=>{

            this.informationService.delete("Aktivite başarıyla silindi.");
            setTimeout(()=>{
                window.location.reload()
            },1500)
        },(err:any)=>{
            this.errorService.errorHandler(err)
        })     
    }
}