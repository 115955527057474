import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { TripPlan } from '../models/tripplan';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EvoTripPlanService {
  private baseApiUrl = environment.apiUrl;

  constructor(private httpClient: HttpClient) { }

  getListByPlanId(planId: number): Observable<TripPlan[]> {
    return this.httpClient.get<TripPlan[]>(this.baseApiUrl + 'GetTripPlanListByPlanId/' + planId);
  }
  getlist(): Observable<TripPlan[]> {
    return this.httpClient.get<TripPlan[]>(this.baseApiUrl + 'GetTripPlanList/');
  }
  get(id: number): Observable<TripPlan> {
    return this.httpClient.get<TripPlan>(this.baseApiUrl + 'GetTripPlan/' + id);
  }
  add(tripPlan: TripPlan): Observable<TripPlan> {
    return this.httpClient.post<TripPlan>(this.baseApiUrl + 'AddTripPlan/', tripPlan);
  }
  update(tripPlan: TripPlan): Observable<TripPlan> {
    return this.httpClient.post<TripPlan>(this.baseApiUrl + "UpdateTripPlan/", tripPlan);
  }
  delete(id: number): Observable<TripPlan> {
    return this.httpClient.post<TripPlan>(this.baseApiUrl + "DeleteTripPlan/" + id, id);
  }
}
