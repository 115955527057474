import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Competence } from '../models/competence';

@Injectable({
  providedIn: 'root'
})
export class EvoAuditService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getChronologicalList(ticketId:number):Observable<any[]>{
    return this.httpClient.get<any[]>(this.baseApiUrl+'GetChronologicalList/' + ticketId);
  }

}
