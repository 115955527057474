export class Plan{
    id:number;
    title:string;
    description:string;
    userId:number;
    userName:string;
    partnerId:number;
    partnerName:string;
    startDate:Date;
    endDate:Date;
    typeId:number;
    planTypeName:string;
    locationTypeId:number;
    locationTypeName:string;
    saturdayExcluded:boolean;
    sundayExcluded:boolean;
    createdBy:number;
    updatedBy:number;
    deletedBy:number;
}