<p-confirmDialog appendTo="body" [baseZIndex]="10000" #confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>

<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="ngx-datatable-kitchen-sink">
            <core-card-snippet>
                <h4 class="card-title">Dönemler Listesi</h4>
                <div class="row">
                    <div class="filter mt-1">

                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-12 mb-1 ml-1">
                        <button class="btn btn-primary btn-sm ml-1 mr-1" data-bs-toggle="modal"
                            data-bs-target="#addterm" (click)="clear();action='Ekle'" rippleEffect title="Yeni Dönem Ekle">
                            <i data-feather="plus"></i><span class="d-none d-sm-inline-block"></span>
                        </button>
                    </div>
                </div>
                <table class="table table-hover table-bordered" id="excel-table" style="display: none;">
                    <thead>
                        <tr>
                            <td>Ay</td>
                            <td>Yıl</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of annualTermList">
                            <td>{{item.month}}</td>
                            <td>{{item.year}}</td>
                        </tr>
                    </tbody>
                </table>
                <p-table [value]="annualTermList" rowExpandMode="single" sortField="year" sortMode="single" dataKey="year" rowGroupMode="subheader" groupRowsBy="year">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width:50%">Ay</th>
                            <th style="width:50%">Aktiflik Durumu</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="groupheader" let-item let-rowIndex="rowIndex" let-expanded="expanded">
                      <tr>
                        <td colspan="2">
                            <button type="button" pButton pRipple [pRowToggler]="item" class="p-button-text p-button-rounded p-button-plain mr-2" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                            <img src="https://cdn-icons-png.flaticon.com/512/55/55281.png" width="32" style="vertical-align: middle" />
                            <span class="font-bold ml-2"><strong>{{ item.year}}</strong></span>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="rowexpansion" let-item>
                        <tr>
                            <td>
                                {{item.monthName}}
                            </td>
                            <td>
                                <p-inputSwitch [(ngModel)]="item.isInProgress" (ngModelChange)="updateTerm(item)"></p-inputSwitch>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </core-card-snippet>
        </section>
    </div>
</div>

<div class="modal fade" id="addterm" tabindex="-1" aria-labelledby="staticBackdropLabel"
aria-hidden="true">
<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h1 class="modal-title fs-5" id="staticBackdropLabel">Yeni Dönem Ekle</h1>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                    data-feather="x-circle" class="mr-50"></i>Çıkış</button>
        </div>
        <div class="modal-body">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <div class="flex flex-wrap p-fluid">
                                    <div class="flex-auto">
                                        <label for="icondisplay" class="block mb-1" style="font-size: 14px;"><b>Yıl</b></label>
                                        <p-calendar 
                                            [(ngModel)]="selectedYear"
                                            [iconDisplay]="'input'" 
                                            [showIcon]="true"
                                            view="year" 
                                            dateFormat="yy"
                                            inputId="icondisplay"
                                            ></p-calendar>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-2 text-right">
                            <button type="button" (click)="addTerm()" rippleEffect class="btn btn-success"
                              [disabled]="selectedYear==null || selectedYear==undefined">Kaydet</button>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</div>
</div>
