import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Partner } from 'app/evo/models/partner';
import { Plan } from 'app/evo/models/plan';
import { PlanType } from 'app/evo/models/plantype';
import { User } from 'app/evo/models/user';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoPartnerService } from 'app/evo/services/evo-customer.service';
import { EvoPlanService } from 'app/evo/services/evo-plan.service';
import { EvoTransactionService } from 'app/evo/services/evo-transaction.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { InformationService } from 'app/evo/services/information.service';

@Component({
  selector: 'app-evo-plan-add-form',
  templateUrl: './evo-plan-add-form.component.html',
  styles: [
  ]
})
export class EvoPlanAddFormComponent implements OnInit {

  type:PlanType=new PlanType();
  types:PlanType[]=[
    {id:1, name:"Destek Planı"},
    {id:2, name:"Proje Planı"},
    {id:3, name:"İzinler"}
  ];

  users:User[]=[];
  partners:Partner[]=[];

  user:User=new User();
  partner:Partner=new Partner();

  planIsInPlace:boolean=false;

  constructor(
    private planService: EvoPlanService,
    private partnerService:EvoPartnerService,
    private userService:EvoUserService,
    private transactionService: EvoTransactionService,
    private router: Router,
    private informationService: InformationService,
    private errorService: ErrorService,
    private decodeService:DecodeService,
  ) { }

  plan: Plan = new Plan();
  myId:number;
  ngOnInit(): void {
    let item=this.decodeService.getRole();
    this.myId=this.decodeService.getUserId();
    if(item!="Admin"){
      this.router.navigate(['/home']);
      this.transactionService.saveTransaction(0, 11, 5, this.myId).subscribe((res: any) => {
      });
    }
    this.partnerService.getList().subscribe((res:any)=>{
      this.partners=res.data;
    });
    this.userService.getList().subscribe((res:any)=>{
      this.users=res.data;
    });
  }

  add(title:string,description:string,starDate:string,endDate:string) {

    var realStartDate = new Date(starDate);
    realStartDate.setHours(realStartDate.getHours() - (realStartDate.getTimezoneOffset() / 60));
    var realEndDate = new Date(endDate);
    realEndDate.setHours(realEndDate.getHours() - (realEndDate.getTimezoneOffset() / 60));

    this.plan={
      id:0,
      title:title,
      description:description,
      startDate:realStartDate,
      endDate:realEndDate,
      userId:this.user.id,
      partnerId:this.partner.id,
      typeId:this.type.id,
      userName:"",
      partnerName:"",
      planTypeName:"",
      locationTypeId:this.planIsInPlace == true?3:4,
      locationTypeName:"",
      saturdayExcluded:false,
      sundayExcluded:false,
      createdBy: this.myId,
      updatedBy: 0,
      deletedBy: 0,
    }

    this.planService.add(this.plan).subscribe((item:any)=>{
      this.informationService.add(item.message);
    },(err)=>{
      this.errorService.errorHandler(err);
    },()=>{
    
    setTimeout(() => {
      this.router.navigate(["/home/plans"]);
    }, 1500);
    });
  }
}
